import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import { Box, CardActions, Collapse, Divider, Tooltip } from "@mui/material";

// ==============================|| CLIPBOARD & HIGHLIGHTER   ||============================== //

const Highlighter = ({ children }) => {
  const [highlight] = useState(false);

  return (
    <Box sx={{ position: "relative" }}>
      <CardActions
        sx={{ justifyContent: "flex-end", p: 1, mb: highlight ? 1 : 0 }}
      >
        <Box sx={{ display: "flex", position: "inherit", right: 0, top: 6 }}>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ mx: 1 }}
          />
          <Tooltip title="Show the source" placement="top-end"></Tooltip>
        </Box>
      </CardActions>
      <Collapse in={highlight}></Collapse>
    </Box>
  );
};

Highlighter.propTypes = {
  children: PropTypes.node,
};

export default Highlighter;
