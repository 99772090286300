export const initialValuesForm = {
  cadastroSocialLogin: false,
  acessoCliente: true,
  antiFraudActive: true,
  firstName: "",
  lastName: "",
  whatsapp: "",
  email: "",
  name: "",
  cpf: "",
  cnpj: "",
  idCompany: 1073,
  personType: "PF",
  companyName: "",
  billValue: "",
  province: "",
  howDidYouGetUs: "",
  zipCode: "",
  street: "",
  number: "",
  complement: "",
  neighborhood: "",
  city: "",
  password: "",
  newPassword: "",
  confirmPassword: "",
  aceita_email: true,
  aceita_push: true,
  prazoQuitar: "72horas",
};
