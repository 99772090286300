import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  usuarioLogado: {},
  companyBase: {},
  permissions: {},
};
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsuarioLogado(state, action) {
      state.usuarioLogado = action.payload.usuarioLogado;
    },
    resetUser(state) {
      state = initialState;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
    setCompanyBase(state, action) {
      state.companyBase = action.payload;
    },
  },
});

export default user.reducer;

export const { setUsuarioLogado, resetUser, setCompanyBase, setPermissions } =
  user.actions;
