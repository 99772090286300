import { createSlice } from "@reduxjs/toolkit";
import Moment from "moment";
const initialState = {
  filtros: {
    status: "todos",
    dataInicial: Moment().set(2023, "year").startOf("year").toDate(),
    dataFinal: new Date(),
    usuario: undefined,
    pesquisa: "",
  },
};
const dashboardParceiro = createSlice({
  name: "dashboardParceiro",
  initialState,
  reducers: {
    setFiltros(state, action) {
      state.filtros = action.payload;
    },
    resetDashboardParceiro(state) {
      state.filtros = initialState.filtros;
    },
  },
});

export default dashboardParceiro.reducer;

export const { setFiltros, resetDashboardParceiro } = dashboardParceiro.actions;
