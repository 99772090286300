import { Box, ButtonBase, Stack, Typography } from "@mui/material";

const Profile = () => {
  const handleLogout = async () => {
    sessionStorage.removeItem("dadosUsuarioLogado");
    window.location.href = "/";
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: "transparent",
          borderRadius: 1,
          "&:hover": { bgcolor: "secondary.lighter" },
        }}
        aria-label="open profile"
        aria-haspopup="true"
        onClick={handleLogout}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          style={{ color: "#03d8d3" }}
          sx={{ p: 0.5 }}
        >
          <Typography variant="subtitle1">SAIR</Typography>
        </Stack>
      </ButtonBase>
    </Box>
  );
};

export default Profile;
