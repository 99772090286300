import * as React from "react";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import { formatMonetario } from "@/services/utils";
import Button from "@mui/material/Button";
import "./styleDashboard.scss";
import SendIcon from "@mui/icons-material/Send";
export default function ItemSimulacao({ simulacao, coresAcoes }) {
  const [anchorElExportarDados, setAnchorElExportarDados] =
    React.useState(null);
  const openBotaoComprovante = Boolean(anchorElExportarDados);

  const navigation = useNavigate();

  const executarAcao = () => {
    navigation(coresAcoes.operacao);
  };
  const handleClickBotaoComprovante = (event) => {
    setAnchorElExportarDados(event.currentTarget);
  };
  const handleCloseBotaoComprovante = () => {
    setAnchorElExportarDados(null);
  };
  return (
    <React.Fragment>
      <Typography
        component="h2"
        variant="h6"
        style={{ color: coresAcoes.botao }}
      >
        {Moment(simulacao.insertDatetime).format("DD/MM/YYYY HH:mm")} <br /> ID:{" "}
        {simulacao.idRequestView}
      </Typography>
      <div style={{ height: "200px" }}>
        <Typography>
          <b>{coresAcoes.texto}</b>
        </Typography>
        <Typography component="p">
          {simulacao.step === 4 && (
            <>Valor contratado: {formatMonetario(simulacao.billValue)}</>
          )}
          {simulacao.step !== 4 && (
            <>Valor simulado: {formatMonetario(simulacao.billValue)}</>
          )}
        </Typography>
        <Typography>
          {simulacao.installments && (
            <>
              Parcelas: {simulacao.installments} de{" "}
              {formatMonetario(simulacao.installmentValue)}
            </>
          )}
        </Typography>
        <Typography></Typography>
        {simulacao.couponCode && (
          <Typography>
            Cupom: <b>{simulacao.couponCode}</b>
          </Typography>
        )}
      </div>
      {coresAcoes.operacao && (
        <Button
          variant="text"
          type="button"
          fullWidth
          sx={{ mt: 1 }}
          startIcon={<SendIcon />}
          style={{ color: coresAcoes.botao, borderColor: coresAcoes.botao }}
          onClick={() => {
            executarAcao();
          }}
        >
          {coresAcoes.textoBotao || ""}
        </Button>
      )}
      {simulacao.comprovantes?.length === 1 && (
        <Button
          variant="text"
          type="button"
          fullWidth
          sx={{ mt: 1 }}
          startIcon={<SendIcon />}
          style={{ color: coresAcoes.botao, borderColor: coresAcoes.botao }}
          onClick={() => {
            window.open(simulacao.comprovantes[0].urlComprovante, "_blank");
          }}
        >
          ABRIR COMPROVANTE
        </Button>
      )}
      {simulacao.comprovantes?.length > 1 && (
        <>
          <Button
            id="button-comprovante"
            aria-controls={openBotaoComprovante ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openBotaoComprovante ? "true" : undefined}
            variant="outlined"
            type="button"
            fullWidth
            sx={{ mt: 1 }}
            startIcon={<SendIcon />}
            style={{ color: coresAcoes.botao, borderColor: coresAcoes.botao }}
            onClick={handleClickBotaoComprovante}
          >
            ABRIR COMPROVANTE
          </Button>
          <Menu
            id="button-comprovante"
            anchorEl={anchorElExportarDados}
            open={openBotaoComprovante}
            onClose={handleCloseBotaoComprovante}
            MenuListProps={{
              "aria-labelledby": "button-comprovante",
            }}
          >
            {simulacao.comprovantes.map((comprovante, index) => {
              return (
                <MenuItem
                  key={comprovante.idComprovante}
                  onClick={() => {
                    window.open(comprovante.urlComprovante, "_blank");
                  }}
                >
                  Comprovante {index + 1}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </React.Fragment>
  );
}
