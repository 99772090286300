import Container from "@mui/material/Container";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { formatMonetario } from "@/services/utils";
import DownloadIcon from "@mui/icons-material/Download";
import QRCode from "react-qr-code";
import { setInfoToaster } from "@/store/reducers/geral";
export default function LinkPagamento() {
  const exportRef = useRef();
  const dispatch = useDispatch();
  const [carregar, setCarregar] = useState(false);
  const [dadosSimulacao, setDadosSimulacao] = React.useState({
    paymentLink: "",
  });
  useEffect(() => {
    setCarregar(true);
  }, []);

  useEffect(() => {
    const carregarDados = async () => {
      let dados = sessionStorage.getItem("dadosSimulacao");
      setDadosSimulacao(JSON.parse(dados));
    };
    if (carregar) {
      setCarregar(false);
      carregarDados();
    }
  }, [carregar]);

  const downloadQrCode = () => {
    const svg = exportRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 1, md: 3 }, p: { xs: 1, md: 3 } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="success" style={{ width: "100%" }}>
              <Typography>Link gerado com sucesso. </Typography>
              {!!dadosSimulacao.idRequest && (
                <Typography>
                  Id pedido: <b>{dadosSimulacao.idRequest}</b>
                </Typography>
              )}
              {dadosSimulacao.billValue > 0 && (
                <Typography>
                  Valor solicitado:{" "}
                  <b>{formatMonetario(dadosSimulacao.billValue)}</b>
                </Typography>
              )}

              {!!dadosSimulacao.cupom && (
                <Typography>
                  Cupom: <b>{dadosSimulacao.cupom}</b>
                </Typography>
              )}
              {!!dadosSimulacao.multipleRequests && (
                <Typography>
                  Você pode compartilhar este link com diversos clientes.
                </Typography>
              )}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">
              Copie o link abaixo para finalizar a contratação:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href={dadosSimulacao.paymentLink}
              target="blank"
            >
              <div
                style={{
                  wordWrap: "break-word",
                  cursor: "pointer",
                  padding: "5px",
                  border: "solid",
                  borderColor: "#cfcfcf",
                  borderWidth: "1px",
                }}
              >
                {dadosSimulacao.paymentLink}
              </div>
            </a>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="text"
              component="label"
              startIcon={<ContentCopyIcon />}
              onClick={() => {
                navigator.clipboard.writeText(dadosSimulacao.paymentLink);
                dispatch(
                  setInfoToaster({
                    open: true,
                    message: "Copiado",
                    type: "success",
                  })
                );
              }}
              fullWidth
            >
              COPIAR LINK
            </Button>
          </Grid>
          {dadosSimulacao.paymentLink && (
            <>
              <Grid item xs={12} style={{ marginTop: "1rem" }} align="center">
                <QRCode
                  ref={exportRef}
                  size={256}
                  style={{ height: "auto", maxWidth: "200px", width: "100%" }}
                  value={dadosSimulacao.paymentLink}
                  viewBox={`0 0 256 256`}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  component="label"
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    downloadQrCode();
                  }}
                  fullWidth
                >
                  BAIXAR QR code
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}
