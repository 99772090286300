import { lazy } from "react";
import Loadable from "@/components/Loadable";
import SimulacaoParceiro from "@/pages/parceiro/simular/SimulacaoParceiro";
import MainLayoutParceiro from "@/layout/MainLayoutParceiro";
import { Navigate } from "react-router-dom";
import Finalizacao from "@/pages/parceiro/simular/Finalizacao";

const Pagamento = Loadable(
  lazy(() => import("@/pages/common/simulacao/Pagamento"))
);
const ControleAcesso = Loadable(
  lazy(() => import("@/pages/parceiro/controle-acesso"))
);
const Usuarios = Loadable(lazy(() => import("@/pages/parceiro/usuarios")));

const LinkPagamento = Loadable(
  lazy(() => import("@/pages/common/simulacao/LinkPagamento"))
);

const PerfilAcesso = Loadable(
  lazy(() => import("@/pages/parceiro/controle-acesso/perfilAcesso"))
);
const CadastroPerfilAcesso = Loadable(
  lazy(() => import("@/pages/parceiro/controle-acesso/perfilAcesso/cadastro"))
);
const PagamentosParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/parceiros/pagamentos"))
);
const Usuario = Loadable(
  lazy(() => import("@/pages/parceiro/usuarios/usuario"))
);

const LinksGerados = Loadable(
  lazy(() => import("@/pages/parceiro/links-gerados"))
);

const Pedidos = Loadable(lazy(() => import("@/pages/parceiro/pedidos")));
const Pedido = Loadable(lazy(() => import("@/pages/parceiro/pedidos/pedido")));
const Api = Loadable(lazy(() => import("@/pages/parceiro/api")));
const DashboardDefault = Loadable(
  lazy(() => import("@/pages/parceiro/dashboard-parceiro"))
);
const AlterarSenha = Loadable(
  lazy(() => import("@/pages/parceiro/alterar-senha"))
);

const MeusDados = Loadable(lazy(() => import("@/pages/parceiro/meus-dados")));
const Parceiros = Loadable(lazy(() => import("@/pages/parceiro/parceiros")));

const MeusSaldos = Loadable(lazy(() => import("@/pages/parceiro/meus-saldos")));

const UsuariosParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/parceiros/usuarios-parceiro"))
);
const ComissoesViaCerta = Loadable(
  lazy(() => import("@/pages/parceiro/comissoes-viacerta"))
);
const Comissoes = Loadable(
  lazy(() => import("@/pages/parceiro/comissoes-gerais"))
);

const CadastroParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/parceiros/cadastro-parceiro"))
);
const EdicaoParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/parceiros/edicao-parceiro"))
);
const MainRoutes = {
  path: "dashboard",
  element: <MainLayoutParceiro />,
  children: [
    {
      path: "",
      element: <DashboardDefault />,
    },
    {
      path: "operacoes",
      element: <Pedidos />,
    },

    {
      path: "comissoes",
      element: <ComissoesViaCerta />,
    },
    {
      path: "comissoes-gerais",
      element: <Comissoes />,
    },
    {
      path: "pedido/:idRequest",
      element: <Pedido />,
    },
    {
      path: "simulacao/:idRequest/:tokenRequest/etapa/:etapa",
      element: <SimulacaoParceiro />,
    },
    {
      path: "simulacao/etapa/:etapa",
      element: <SimulacaoParceiro />,
    },
    {
      path: "simular",
      element: <Navigate {...{ to: "/dashboard/simulacao/etapa/1" }} />,
    },

    {
      path: "alterar-senha",
      element: <AlterarSenha />,
    },
    {
      path: "meus-dados",
      element: <MeusDados />,
    },
    {
      path: "parceiros",
      element: <Parceiros />,
    },
    {
      path: "api",
      element: <Api />,
    },
    {
      path: "*",
      element: <DashboardDefault />,
    },
    {
      path: "usuario",
      element: <Usuario />,
    },
    {
      path: "usuario/company/:idCompany",
      element: <Usuario />,
    },
    {
      path: "usuario/company/:idCompany/:idUser",
      element: <Usuario />,
    },
    {
      path: "usuarios-parceiro/:idCompany",
      element: <UsuariosParceiro />,
    },
    {
      path: "clientes",
      element: <Usuarios />,
    },
    {
      path: "link-pagamento",
      element: <LinkPagamento />,
    },
    {
      path: "parceiro",
      element: <CadastroParceiro />,
    },
    {
      path: "links-gerados",
      element: <LinksGerados />,
    },
    {
      path: "perfis-acesso",
      element: <PerfilAcesso />,
    },

    {
      path: "controle-acesso",
      element: <ControleAcesso />,
    },
    {
      path: "perfil-acesso",
      element: <CadastroPerfilAcesso />,
    },
    {
      path: "perfil-acesso/:idAccessProfile",
      element: <CadastroPerfilAcesso />,
    },
    {
      path: "pagamentos-parceiro/:idCompany",
      element: <PagamentosParceiro />,
    },
    {
      path: "parceiro/:idCompany",
      element: <EdicaoParceiro />,
    },
    {
      path: "pagamento/:idRequest/:token/:company",
      element: <Pagamento />,
    },
    {
      path: "pagamento/erro/:idRequest/:token/:company",
      element: <Pagamento />,
    },
    {
      path: "confirmacao/:idRequest/:token/:areaSistema",
      element: <Finalizacao />,
    },
    {
      path: "meus-saldos",
      element: <MeusSaldos />,
    },
  ],
};

export default MainRoutes;
