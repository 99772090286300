import * as React from "react";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import DoneIcon from "@mui/icons-material/Done";
import ListItemText from "@mui/material/ListItemText";
import { formatMonetario } from "@/services/utils";
import { get } from "@/services/api";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
import "./ResultadoSimulacao.scss";

export default function SimulacaoAreaPrivada({
  contratar,
  billValue,
  table,
  setOpcaoParcelamento,
  cupomUtilizar,
  idUser,
  idCompany,
  cashbackValue,
  carregarSimulacao,
  setCarregarSimulacao,
}) {
  const dispatch = useDispatch();
  const [deveConfirmar, setDeveConfirmar] = React.useState(false);
  const [simulou, setSimulou] = React.useState(false);

  const [parcelas, setParcelas] = React.useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    const executaSimulacao = async () => {
      if (simulou) {
        return;
      }
      setSimulou(true);
      try {
        dispatch(activeSpinner());
        let request = await get(
          `public/simulation-values?billValue=${billValue}&cupom=${
            cupomUtilizar || ""
          }&idUser=${idUser || ""}&table=${table || ""}&idCompany=${
            idCompany || ""
          }&cashbackValue=${cashbackValue || ""}&type=${table || ""}`
        );
        dispatch(cancelSpinner());
        if (request.error) {
          dispatch(
            setInfoDialog({
              open: true,
              descricao: request.error,
            })
          );
          return;
        }
        setParcelas(request.data);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao simular! Tente novamente em instantes.",
          })
        );
      }
    };
    if (billValue) {
      executaSimulacao();
    }
  }, [
    billValue,
    simulou,
    table,
    cupomUtilizar,
    idUser,
    idCompany,
    dispatch,
    cashbackValue,
  ]);

  useEffect(() => {
    if (deveConfirmar) {
      setDeveConfirmar(false);
      contratar();
    }
  }, [deveConfirmar, contratar, navigation]);

  useEffect(() => {
    if (carregarSimulacao) {
      setCarregarSimulacao(false);
      setSimulou(false);
    }
  }, [carregarSimulacao, setCarregarSimulacao]);

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List sx={{}}>
              <Grid container spacing={1}>
                {parcelas.map((parcela) => {
                  return (
                    <Grid item xs={12} sm={6}>
                      <ListItem
                        key={parcela.installments}
                        disablePadding
                        style={{
                          border: "solid",
                          borderColor: "#7e7e7e",
                          borderWidth: "1px",
                          borderRadius: "8px",
                          background: "#f8f8f8",
                          textTransform: "uppercase",
                        }}
                        secondaryAction={
                          <DoneIcon
                            style={{
                              color: "#009b97",
                              fontSize: "2.3rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpcaoParcelamento(parcela);
                              setDeveConfirmar(true);
                            }}
                          >
                            Selecionar
                          </DoneIcon>
                        }
                      >
                        <ListItemButton
                          style={{
                            borderBottom: "solid",
                            borderWidth: "1px",
                            borderColor: "#cdcdcd",
                          }}
                          role={undefined}
                          onClick={() => {
                            setOpcaoParcelamento(parcela);
                            setDeveConfirmar(true);
                          }}
                          dense
                        >
                          <ListItemText
                            id={parcela.installments}
                            primary={
                              <>
                                <b style={{ fontSize: "1rem" }}>
                                  {parcela.installments}
                                </b>
                                {` ${
                                  parcela.installments === 1 ? "vez" : "vezes"
                                } de `}
                                {!!parcela.originalParcel && (
                                  <s style={{ color: "#6a6a6a" }}>
                                    {formatMonetario(parcela.originalParcel)}{" "}
                                  </s>
                                )}
                                <b>{formatMonetario(parcela.parcel)}</b>.
                                <br />
                                Total de <b>{formatMonetario(parcela.total)}</b>
                                .
                                {!!cashbackValue && (
                                  <>
                                    <br />
                                    Total sem resgate de Chargeback:
                                    <b>
                                      {formatMonetario(parcela.originalTotal)}
                                    </b>
                                  </>
                                )}
                              </>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </Grid>
                  );
                })}
              </Grid>
            </List>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
