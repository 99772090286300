import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import HistoryIcon from "@mui/icons-material/History";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import ItemSimulacao from "./ItemSimulacao";
import Moment from "moment";
import { get } from "@/services/api";
import "./styleDashboard.scss";
import { setUsuarioLogado } from "@/store/reducers/user";
import { useDispatch } from "react-redux";

import { activeSpinner, cancelSpinner } from "@/store/reducers/geral";

const DashboardDefault = () => {
  const [todoHistorico, setTodoHistorico] = useState(false);
  const [carregar, setCarregar] = useState(false);
  const [carregarHistorico, setCarregarHistorico] = useState(false);
  const [simulacoes, setSimulacoes] = useState([]);
  const [infoAlert, setInfoAlert] = React.useState({});
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const sair = useCallback(() => {
    sessionStorage.removeItem("dadosUsuarioLogado");
    navigation("/app/login");
  }, [navigation]);

  useEffect(() => {
    if (!carregar) {
      return;
    }
    setCarregar(false);
    const dadosUsuario = sessionStorage.getItem("dadosUsuarioLogado");
    if (dadosUsuario) {
      const dados = JSON.parse(dadosUsuario);
      if (new Date(dados.expirationDate) > new Date()) {
        dispatch(setUsuarioLogado({ usuarioLogado: dados }));
        setCarregarHistorico(true);
        return;
      }
    }
    sair();
  }, [carregar, sair, dispatch]);

  const getCorStatus = (simulacao) => {
    if (simulacao.chargebackDatetime) {
      return {
        borda: "#ff9178",
        botao: "#dd0000",
        texto: "Pagamento Estornado.",
      };
    }
    //resultado pagamento
    if (simulacao.endDatetime) {
      return {
        borda: "#a3d1a3",
        botao: "green",
        texto: "Finalizado. Boleto/PIX quitado com sucesso.",
      };
    }
    if (simulacao.paymentStatus === "payment-ok") {
      return {
        borda: "#a3d1a3",
        botao: "green",
        texto: "Pagamento recebido. Em breve seu boleto/PIX será quitado.",
      };
    }
    if (simulacao.paymentStatus === "payment-error") {
      return {
        borda: "#ff9178",
        botao: "#dd0000",
        textoBotao: "SIMULAR NOVAMENTE",
        operacao: `/app/area-cliente/simulacao`,
        texto: "Pagamento não autorizado, por favor tente novamente.",
      };
    }
    let dataValida = Moment()
      .subtract(1, "week")
      .isBefore(Moment(simulacao.insertDatetime));
    if (simulacao.step === 3) {
      return {
        borda: "#dfd381",
        botao: "#a59000",
        textoBotao: "EFETUAR PAGAMENTO",
        operacao: dataValida
          ? `/app/pagamento/${simulacao.idRequest}/${simulacao.token}`
          : null,
        texto: "Aguardando pagamento",
      };
    }

    if (simulacao.step === 4) {
      return {
        borda: "#dfd381",
        botao: "#a59000",
        textoBotao: "EFETUAR PAGAMENTO",
        operacao: dataValida
          ? `/app/pagamento/${simulacao.idRequest}/${simulacao.token}`
          : null,
        texto: "Aguardando pagamento",
      };
    }
    return { borda: "gray", botao: "gray" };
  };

  useEffect(() => {
    const carregar = async () => {
      try {
        setCarregarHistorico(false);
        dispatch(activeSpinner());

        const result = await get(
          `secure/requests/user?historico=${todoHistorico ? "S" : "N"}`
        );
        dispatch(cancelSpinner());
        if (result.error) {
          setInfoAlert({ open: true, descricao: result.error });
          return;
        }
        if (!result.data) {
          sair();
          return;
        }
        setSimulacoes(result.data);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        setInfoAlert({
          open: true,
          descricao:
            "Ocorreu um erro ao consultar seus dados. Por favor atualize a página!",
        });
      }
    };
    if (carregarHistorico) {
      carregar();
    }
  }, [carregarHistorico, dispatch, sair, todoHistorico]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  return (
    <Container>
      {simulacoes.length === 0 && (
        <Typography>Você ainda não realizou nenhum pedido.</Typography>
      )}
      {simulacoes.length > 0 && (
        <>
          <Typography variant="h5">ÚLTIMOS PEDIDOS:</Typography>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 3 } }}
          >
            {infoAlert.open && (
              <Grid container spacing={1} style={{ marginBottom: "2rem" }}>
                <Grid item xs={12}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setInfoAlert({ open: false });
                    }}
                  >
                    {infoAlert.descricao}
                  </Alert>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={3}>
              <>
                {simulacoes.map((simulacao) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={5}
                      lg={4}
                      key={"grid" + simulacao.idRequest}
                    >
                      <Paper
                        key={"paper" + simulacao.idRequest}
                        className="item_simulacao"
                        style={{ borderColor: getCorStatus(simulacao).borda }}
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: 220,
                        }}
                      >
                        <ItemSimulacao
                          key={"item" + simulacao.idRequest}
                          {...{
                            simulacao,
                            coresAcoes: getCorStatus(simulacao),
                          }}
                        />
                      </Paper>
                    </Grid>
                  );
                })}
                {simulacoes.length > 0 && !todoHistorico && (
                  <Grid item xs={12}>
                    <Button
                      variant="text"
                      startIcon={<HistoryIcon />}
                      fullWidth
                      onClick={() => {
                        setTodoHistorico(true);
                        setCarregarHistorico(true);
                      }}
                    >
                      VER TODO HISTÓRICO
                    </Button>
                  </Grid>
                )}
              </>
            </Grid>
          </Paper>
        </>
      )}
    </Container>
  );
};

export default DashboardDefault;
