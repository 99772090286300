import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useRef } from "react";
import Button from "@mui/material/Button";
import ListIcon from "@mui/icons-material/List";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { formatMonetario } from "@/services/utils";
import { apenasNumeros } from "@/services/utils";
import ResultadoSimulacao from "../ResultadoSimulacao";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import {
  activeSpinner,
  cancelSpinner,
  setInfoToaster,
} from "@/store/reducers/geral";

export default function EtapaSimulacao({
  permiteVoltarEtapa,
  salvarSimulacao,
  salvarSimulacaoParaLink,
  setNavegarEtapa,
  dadosUsuario,
  setDadosUsuario,
  carregarEtapa,
  ehParceiro,
  abrirLinkPagamento,
}) {
  const exportRef = useRef();
  const dispatch = useDispatch();
  const [carregar, setCarregar] = React.useState(false);
  const [carregarSimulacao, setCarregarSimulacao] = React.useState(false);

  const [opcaoParcelamento, setOpcaoParcelamento] = React.useState({});
  const [anchorElExportarDados, setAnchorElExportarDados] =
    React.useState(null);
  const openBotaoExportarDados = Boolean(anchorElExportarDados);

  const handleClickBotaoExportarDados = (event) => {
    setAnchorElExportarDados(event.currentTarget);
  };

  const handleCloseBotaoExportarDados = () => {
    setAnchorElExportarDados(null);
  };

  const exportarSimulacao = async () => {
    dispatch(activeSpinner());
    const canvas = await html2canvas(exportRef.current);
    canvas.toBlob((blob) => {
      navigator.clipboard.write([
        new window.ClipboardItem({ "image/png": blob }),
      ]);
    }, "image/png");
    dispatch(
      setInfoToaster({
        open: true,
        message: "Copiado",
        type: "success",
      })
    );
    dispatch(cancelSpinner());
  };

  const contratar = async () => {
    if (!opcaoParcelamento) {
      return;
    }
    dispatch(activeSpinner());
    dadosUsuario.installments = opcaoParcelamento.installments;
    dadosUsuario.interest = opcaoParcelamento.interest;
    dadosUsuario.installmentValue = opcaoParcelamento.parcel;
    await salvarSimulacao(dadosUsuario);
    setNavegarEtapa(3);
  };

  const criarLink = async () => {
    const dadosUsuarioSalvo = await salvarSimulacaoParaLink(dadosUsuario);
    abrirLinkPagamento(dadosUsuarioSalvo);
  };
  const Schema = Yup.object().shape({
    name: Yup.string().when("acessoCliente", {
      is: true,
      then: Yup.string().nullable().required("Informe o nome"),
    }),

    whatsapp: Yup.string().when("acessoCliente", {
      is: true,
      then: Yup.string()
        .test("len", "Celular inválido", (val) => {
          return apenasNumeros(val).length === 11;
        })
        .required("Informe seu celular"),
    }),
    email: Yup.string().when("acessoCliente", {
      is: true,
      then: Yup.string()
        .email("E-mail inválido")
        .required("Informe seu e-mail"),
    }),
    billValue: Yup.number().required("Informe o valor da simulação"),
  });
  const formik = useFormik({
    initialValues: { billValue: "" },
    validationSchema: Schema,
    onSubmit: async (values) => {
      setDadosUsuario({ ...dadosUsuario, billValue: values.billValue });
      setCarregarSimulacao(true);
    },
  });

  useEffect(() => {
    const carregarDados = async () => {
      formik.setFieldValue("billValue", dadosUsuario.billValue);
    };
    if (carregar) {
      setCarregar(false);
      carregarDados();
      return;
    }
  }, [carregar, dispatch, formik, dadosUsuario.billValue]);

  useEffect(() => {
    setCarregar(true);
  }, []);
  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              <Grid item xs={12} sm={4}>
                <TextField
                  type="number"
                  name="billValue"
                  label={"Valor total da simulação *"}
                  fullWidth
                  onWheel={() => document.activeElement.blur()}
                  variant="standard"
                  placeholder="R$ 0,00"
                  value={formik.values.billValue}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    formik.touched.billValue && Boolean(formik.errors.billValue)
                  }
                  helperText={
                    formik.touched.billValue && formik.errors.billValue
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button variant="contained" type="submit">
                  SIMULAR NOVAMENTE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <div ref={exportRef}>
        {!dadosUsuario?.infoPartner?.billValue && (
          <Typography align="center">
            Valor simulado: <b>{formatMonetario(dadosUsuario.billValue)}</b>.
          </Typography>
        )}

        <Typography align="center" style={{ marginBottom: "0.5rem" }}>
          Para contratar selecione uma opção abaixo:
        </Typography>
        {carregarEtapa && (
          <ResultadoSimulacao
            {...{
              contratar,
              billValue: dadosUsuario.billValue,
              table: dadosUsuario.table,
              idCompany: dadosUsuario.idCompany,
              cupomUtilizar: dadosUsuario.cupom,
              setOpcaoParcelamento,
              carregarSimulacao,
              setCarregarSimulacao,
            }}
          ></ResultadoSimulacao>
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6}>
          {permiteVoltarEtapa() && (
            <Box>
              <Button
                onClick={() => {
                  setNavegarEtapa(1);
                }}
                sx={{ mt: 3, ml: 1 }}
              >
                VOLTAR
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
