export default function LogoBranco(props) {
  return (
    <img
      src="/logo_branco.png"
      style={{ maxWidth: "110px" }}
      alt=""
      {...props}
    ></img>
  );
}
