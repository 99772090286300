import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { get } from "@/services/api";
import { setUsuarioLogado } from "@/store/reducers/user";
import { useDispatch, useSelector } from "react-redux";
import DialogCupom from "./dialogCupom";
import {
  activeSpinner,
  setInfoDialog,
  cancelSpinner,
} from "@/store/reducers/geral";
import "./inicio.scss";
import { formatMonetario } from "@/services/utils";

const Inicio = () => {
  const [infoDialogCupom, setInfoDialogCupom] = useState({
    open: false,
  });
  const [carregar, setCarregar] = useState(false);
  const [infoAlert, setInfoAlert] = React.useState({});
  const [continuarSimulacao, setContinuarSimulacao] = React.useState(null);
  const user = useSelector((state) => state.user);
  const { usuarioLogado } = user;
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const sair = useCallback(() => {
    sessionStorage.removeItem("dadosUsuarioLogado");
    navigation("/app/login");
  }, [navigation]);

  const pagar = useCallback(
    (idRequest, token) => {
      navigation(`/app/area-cliente/pagamento/${idRequest}/${token}`);
    },
    [navigation]
  );

  useEffect(() => {
    const carregarUltimaOperacao = async () => {
      try {
        dispatch(activeSpinner());

        const result = await get(`secure/requests/continue-request`);
        dispatch(cancelSpinner());
        if (result && result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        setContinuarSimulacao(result);
        if (
          result &&
          result.idRequest.toString() ===
            sessionStorage.getItem("idRequestPayment")
        ) {
          sessionStorage.removeItem("idRequestPayment");
          sessionStorage.removeItem("idRequestToken");
          pagar(result.idRequest, result.token);
          return;
        }
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao consultar os dados. Por favor atualize a página!",
          })
        );
      }
    };

    if (!carregar) {
      return;
    }

    setCarregar(false);
    const dadosUsuario = sessionStorage.getItem("dadosUsuarioLogado");
    if (dadosUsuario) {
      const dados = JSON.parse(dadosUsuario);
      if (new Date(dados.expirationDate) > new Date()) {
        dispatch(setUsuarioLogado({ usuarioLogado: dados }));

        carregarUltimaOperacao();
        return;
      }
    }
    sair();
  }, [carregar, pagar, sair, dispatch]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  return (
    <Container>
      <Paper
        variant="outlined"
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={1} style={{ marginBottom: "1rem" }}>
          {infoAlert.open && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                onClose={() => {
                  setInfoAlert({ open: false });
                }}
              >
                {infoAlert.descricao}
              </Alert>
            </Grid>
          )}
          {continuarSimulacao && continuarSimulacao.idRequest && (
            <Grid item xs={12}>
              <Alert severity="info" style={{ margin: "1rem" }}>
                <Typography>
                  Você possui uma <b>simulação</b> pendente no valor de{" "}
                  <b>{continuarSimulacao.installments}</b>x de{" "}
                  <b>{formatMonetario(continuarSimulacao.installmentValue)}</b>.
                </Typography>
                <Typography>
                  ID: <b>{continuarSimulacao.idRequestView}</b>
                </Typography>
                <Typography>Pagamentos solicitados:</Typography>
                {continuarSimulacao.boletos.map((boletoPix) => {
                  return (
                    <div key={boletoPix.idRequestBill}>
                      {boletoPix.beneficiary && (
                        <Typography>
                          PIX para <b>{boletoPix.beneficiary}</b> - Chave:{" "}
                          <b>{boletoPix.barcode}</b>
                        </Typography>
                      )}
                      {!boletoPix.beneficiary && (
                        <Typography>
                          Código de barras: <b>{boletoPix.barcode}</b>
                        </Typography>
                      )}
                    </div>
                  );
                })}
                {continuarSimulacao.arquivos.map((arquivo) => {
                  return (
                    <Typography
                      key={arquivo.idRequestFile}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(arquivo.file, "_blank");
                      }}
                    >
                      <b>{arquivo.fileName}</b>
                    </Typography>
                  );
                })}
                <Button
                  variant="contained"
                  type="button"
                  sx={{ mt: 1 }}
                  onClick={() => {
                    pagar(
                      continuarSimulacao.idRequest,
                      continuarSimulacao.token
                    );
                  }}
                >
                  REALIZAR PAGAMENTO
                </Button>
              </Alert>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={1} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12}>
            <Typography align="center">
              Olá {usuarioLogado ? usuarioLogado.name : ""}
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography>Em que podemos te ajudar hoje?</Typography>
          </Grid>
        </Grid>

        <Container align="center">
          <Grid
            container
            spacing={2}
            maxWidth="sm"
            sx={{ pb: 2 }}
            justifyContent="center"
          >
            <Grid item xs={6} md={4}>
              <div
                className="botao-opcao"
                onClick={() => {
                  navigation("/app/area-cliente/simulacao");
                }}
              >
                <img src="/icon-contratar.png" alt=""></img>
                <div className="texto">
                  <div className="titulo">QUERO CONTRATAR</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div
                className="botao-opcao"
                onClick={() => {
                  navigation("/app/area-cliente/simulacao");
                }}
              >
                <img src="/icon-simular.png" alt=""></img>
                <div className="texto">
                  <div className="titulo">NOVA SIMULAÇÃO</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div
                className="botao-opcao"
                onClick={() => {
                  setInfoDialogCupom({ open: true });
                }}
              >
                <img src="/icon-cupom.png" alt=""></img>
                <div className="texto">
                  <div className="titulo">RESGATAR CUPOM</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div
                className="botao-opcao"
                onClick={() => {
                  navigation("/app/area-cliente/pedidos");
                }}
              >
                <img src="/icon-historico.png" alt=""></img>
                <div className="texto">
                  <div className="titulo">MEUS PEDIDOS</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div
                className="botao-opcao"
                onClick={() => {
                  navigation("/app/area-cliente/meus-dados");
                }}
              >
                <img src="/icon-user.png" alt=""></img>
                <div className="texto">
                  <div className="titulo">MEUS DADOS</div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <DialogCupom
        {...{
          infoDialogCupom,
          setInfoDialogCupom,
        }}
      />
    </Container>
  );
};

export default Inicio;
