import MainLayout from "@/layout/MainLayout";

import Historico from "@/pages/cliente/areaPrivada/historico";
import Inicio from "@/pages/cliente/areaPrivada/inicio";
import MeusDados from "@/pages/cliente/areaPrivada/meus-dados/MeusDados";
import SimulacaoClienteComLogin from "@/pages/cliente/areaAberta/simulacao/SimulacaoClienteComLogin";
import Finalizacao from "@/pages/common/simulacao/Finalizacao";
import Pagamento from "@/pages/common/simulacao/Pagamento";
import AlterarSenha from "@/pages/cliente/areaPrivada/alterar-senha/AlterarSenha";
import Cashback from "@/pages/cliente/areaPrivada/cashback";
const MainRoutes = {
  path: "app/area-cliente",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <Inicio />,
    },
    {
      path: "inicio",
      element: <Inicio />,
    },
    {
      path: "pedidos",
      element: <Historico />,
    },
    {
      path: "simulacao",
      element: <SimulacaoClienteComLogin />,
    },
    {
      path: "confirmacao/:idRequest/:token",
      element: <Finalizacao />,
    },
    {
      path: "simulacao/etapa/:etapa",
      element: <SimulacaoClienteComLogin />,
    },
    {
      path: "simulacao/cupom/:cupomUtilizar",
      element: <SimulacaoClienteComLogin />,
    },
    {
      path: "simulacao/:idRequest/:tokenRequest/etapa/:etapa",
      element: <SimulacaoClienteComLogin />,
    },
    {
      path: "pagamento/:idRequest/:token",
      element: <Pagamento />,
    },
    {
      path: "pagamento/erro/:idRequest/:token",
      element: <Pagamento />,
    },
    {
      path: "meus-dados",
      element: <MeusDados />,
    },
    {
      path: "alterar-senha",
      element: <AlterarSenha />,
    },
    {
      path: "cashback",
      element: <Cashback />,
    },
    {
      path: "*",
      element: <Inicio />,
    },
  ],
};

export default MainRoutes;
