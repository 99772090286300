import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { get } from "@/services/api";
import { useFormik } from "formik";
import Paper from "@mui/material/Paper";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import { ThemeProvider } from "@mui/material/styles";
import Logo from "@/components/Logo";
import { useNavigate } from "react-router-dom";
import theme from "../../../theme";
import { useParams } from "react-router-dom";
import NavBar from "@/components/navbar";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";

export default function ResgatarCupom() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [etapaCpf, setEtapaCpf] = React.useState(true);
  const [cupom, setCupom] = React.useState({});

  const Schema = Yup.object().shape({
    cpfCnpj: Yup.string().required("Informe seu CPF ou CNPJ!"),
  });
  const formik = useFormik({
    initialValues: { cpfCnpj: "" },
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        dispatch(activeSpinner());
        const result = await get(
          `public/retrieve-coupon/${values.cpfCnpj}/${token}`
        );
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        setCupom(result);
        setEtapaCpf(false);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Estamos atualizando nosso sistema. Por favor tente novamente em instantes.",
          })
        );
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} align="center">
              <Logo></Logo>
            </Grid>
          </Grid>
          {!etapaCpf && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Alert severity="success" style={{ width: "100%" }}>
                  <AlertTitle>Sucesso</AlertTitle>
                  <Typography>Seu cupom foi resgatado com sucesso.</Typography>
                  <Typography>{cupom.description}</Typography>
                </Alert>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "1rem" }}>
                <Typography>O que mais deseja fazer?</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigation(`/app/login`);
                  }}
                >
                  IR PARA MINHA CONTA
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  VOLTAR AO SITE
                </Button>
              </Grid>
            </Grid>
          )}
          {etapaCpf && (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Typography align="center">
                    Informe seu CPF ou CNPJ para resgatar seu cupom!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="cpfCnpj"
                    name="cpfCnpj"
                    label="CPF ou CNPJ"
                    fullWidth
                    autoComplete="username"
                    variant="standard"
                    value={formik.values.cpfCnpj}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.cpfCnpj && Boolean(formik.errors.cpfCnpj)
                    }
                    helperText={formik.touched.cpfCnpj && formik.errors.cpfCnpj}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{ ml: 1 }}
                  >
                    CONTINUAR
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
