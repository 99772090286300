import Dialog from "@mui/material/Dialog";
import BotoesInicioSimulacao from "@/pages/common/BotoesInicioSimulacao";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

export default function DialogTipoItemPagamento({
  infoDialogTipoItemPagamento,
  setInfoDialogTipoItemPagamento,
}) {
  const handleClose = () => {
    setInfoDialogTipoItemPagamento({ open: false });
  };
  const seleciona = (opcao) => {
    infoDialogTipoItemPagamento.selecionar(opcao);
    handleClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={infoDialogTipoItemPagamento.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ padding: "20px 5px" }}>
        <Typography align="center" sx={{ mb: 1 }}>
          Selecione o que deseja quitar:
        </Typography>
        <BotoesInicioSimulacao
          {...{ iniciaSimulacao: seleciona }}
        ></BotoesInicioSimulacao>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
