import * as React from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import Logo from "@/components/Logo";
import { post } from "@/services/api";
import { useState } from "react";
import EtapaDadosPessoais from "./EtapaDadosPessoais";
import EtapaInicioComLogin from "./EtapaInicioComLogin";
import EtapaSimulacao from "./EtapaSimulacao";
import Inicio from "@/pages/common/simulacao/Inicio";
import EtapaConfirmacao from "./EtapaConfirmacao";
import EtapaItemsPagamento from "./EtapaItemsPagamento";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DialogLoginCliente from "@/pages/cliente/areaAberta/login/DialogLoginCliente";
import { Helmet } from "react-helmet";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";

export default function Simulacao({
  dadosUsuario,
  setDadosUsuario,
  setNavegarEtapa,
  carregarEtapa,
  setCarregarEtapa,
  company,
  limparDados,
  abrirLinkPagamento,
  ehParceiro,
  ehAreaComLogin,
  cupomUtilizar,
}) {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { etapa } = useParams();

  const [activeStep, setActiveStep] = React.useState(1);
  const [infoDialogLogin, setInfoDialogLogin] = useState({ open: false });

  useEffect(() => {
    console.log(activeStep);
    if (etapa) {
      setActiveStep(parseInt(etapa));
    }
    setCarregarEtapa(true);
  }, [etapa, setCarregarEtapa]);

  const avancarParaAutenticacao = () => {
    if (ehParceiro || dadosUsuario.idCompany) {
      setNavegarEtapa(4);
      return;
    }
    if (ehAreaComLogin) {
      navigation(
        `/app/area-cliente/pagamento/${dadosUsuario.idRequest}/${dadosUsuario.token}`
      );
      return;
    }
    sessionStorage.setItem("idRequestPayment", dadosUsuario.idRequest);
    sessionStorage.setItem("idRequestToken", dadosUsuario.token);
    setInfoDialogLogin({
      open: true,
      idRequestBind: dadosUsuario.idRequest,
      tokenRequestBind: dadosUsuario.token,
    });
  };

  const salvarSimulacaoParaLink = async (values) => {
    const dados = { ...values };
    if (ehParceiro) {
      dados.companyStep = etapa;
    }
    return await salvarSimulacao(dados);
  };
  const salvarSimulacao = (values) => {
    var promise = new Promise(async (resolve, reject) => {
      try {
        values = { ...values };

        dispatch(activeSpinner());
        const urlBase = ehAreaComLogin
          ? `secure/requests/simulation`
          : `public/simulation`;
        const result = await post(`${urlBase}/${etapa}`, values);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result.error);
          return;
        }
        resolve(result);
      } catch (e) {
        console.log(e);
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao simular. Tente novamente em instantes!",
          })
        );
        dispatch(cancelSpinner());
        reject(e);
      }
    });
    return promise;
  };

  function getStepContent(step) {
    if (!step) {
      return (
        <Inicio
          {...{
            salvarSimulacao,
            setNavegarEtapa,
            dadosUsuario,
            setDadosUsuario,
            carregarEtapa,
            setCarregarEtapa,
          }}
        />
      );
    }

    const permiteVoltarEtapa = () => {
      if (!dadosUsuario.companyStep) {
        return true;
      }
      return parseInt(etapa) > parseInt(dadosUsuario.companyStep);
    };
    switch (step) {
      case 1: {
        if (ehAreaComLogin) {
          return (
            <EtapaInicioComLogin
              {...{
                salvarSimulacao,
                salvarSimulacaoParaLink,
                setNavegarEtapa,
                dadosUsuario,
                setDadosUsuario,
                carregarEtapa,
                setCarregarEtapa,
                cupomUtilizar,
                abrirLinkPagamento,

                permiteVoltarEtapa,
              }}
            />
          );
        }
        return (
          <EtapaDadosPessoais
            {...{
              salvarSimulacao,
              salvarSimulacaoParaLink,
              setNavegarEtapa,
              dadosUsuario,
              setDadosUsuario,
              carregarEtapa,
              setCarregarEtapa,
              company,
              abrirLinkPagamento,
              limparDados,
              ehParceiro,
              permiteVoltarEtapa,
            }}
          />
        );
      }
      case 2:
        return (
          <EtapaSimulacao
            {...{
              salvarSimulacao,
              salvarSimulacaoParaLink,
              setNavegarEtapa,
              dadosUsuario,
              setDadosUsuario,
              carregarEtapa,
              abrirLinkPagamento,
              setCarregarEtapa,
              ehParceiro,
              permiteVoltarEtapa,
            }}
          />
        );
      case 3:
        return (
          <EtapaItemsPagamento
            {...{
              salvarSimulacao,
              salvarSimulacaoParaLink,
              setNavegarEtapa,
              dadosUsuario,
              setDadosUsuario,
              carregarEtapa,
              abrirLinkPagamento,
              setCarregarEtapa,
              ehParceiro,
              permiteVoltarEtapa,
              avancarParaAutenticacao,
            }}
          />
        );
      case 4:
        return (
          <EtapaConfirmacao
            {...{
              salvarSimulacao,
              salvarSimulacaoParaLink,
              setNavegarEtapa,
              dadosUsuario,
              setDadosUsuario,
              abrirLinkPagamento,
              carregarEtapa,
              setCarregarEtapa,
              permiteVoltarEtapa,
              ehParceiro,
            }}
          />
        );
      default:
        return (
          <Inicio
            {...{
              salvarSimulacao,
              setNavegarEtapa,
              dadosUsuario,
              setDadosUsuario,
              carregarEtapa,
              setCarregarEtapa,
            }}
          />
        );
    }
  }

  return (
    <Container
      component="main"
      maxWidth={activeStep === 1 || activeStep === 2 ? "sm" : "md"}
      sx={{ mb: 4 }}
    >
      <Paper
        variant="outlined"
        sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
        </React.Fragment>
      </Paper>
      {infoDialogLogin.open && (
        <DialogLoginCliente {...{ infoDialogLogin, setInfoDialogLogin }} />
      )}
    </Container>
  );
}
