import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, Toolbar } from "@mui/material";
import Spinner from "@/components/Spinner";
import Toaster from "@/components/Toaster";
import Drawer from "./Drawer";
import Header from "./Header";
import DialogConfirmacao from "@/components/DialogConfirmacao";
import navigation from "@/menu-items";
import Breadcrumbs from "@/components/@extended/Breadcrumbs";
import Dialog from "@/components/Dialog";
import {
  setInfoDialogConfirmacao,
  setInfoDialog,
} from "@/store/reducers/geral";
import { openDrawer } from "@/store/reducers/menu";

const MainLayout = () => {
  const matchDownLG = true;
  const dispatch = useDispatch();
  const geral = useSelector((state) => state.geral);

  const { drawerOpen } = useSelector((state) => state.menu);

  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = (menu?) => {
    if (menu && !matchDownLG) {
      return;
    }
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG, dispatch]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, p: { xs: 1, sm: 2 } }}
      >
        <Toolbar />
        <Breadcrumbs
          navigation={navigation}
          title
          titleBottom
          card={false}
          divider={false}
        />
        <Outlet />
      </Box>
      {geral?.infoDialog?.open && (
        <Dialog {...{ infoDialog: geral.infoDialog, setInfoDialog }} />
      )}
      <Spinner {...{ showSpinner: geral.showSpinner }}></Spinner>
      <Toaster {...{ infoToaster: geral.infoToaster }}></Toaster>
      {geral?.infoDialogConfirmacao.open && (
        <DialogConfirmacao
          {...{
            infoDialogConfirmacao: geral.infoDialogConfirmacao,
            setInfoDialogConfirmacao,
          }}
        />
      )}
    </Box>
  );
};

export default MainLayout;
