import MinimalLayout from "@/layout/MinimalLayout";

import SimulacaoClienteSemLogin from "@/pages/cliente/areaAberta/simulacao/SimulacaoClienteSemLogin";
import RecuperarSenha from "@/pages/cliente/areaAberta/recuperacao-senha/RecuperarSenha";
import EsqueciMinhaSenha from "@/pages/cliente/areaAberta/esqueci-minha-senha/EsqueciMinhaSenha";
import Finalizacao from "@/pages/common/simulacao/Finalizacao";
import ResgatarCupom from "@/pages/cliente/areaAberta/resgatar-cupom";
import Pagamento from "@/pages/common/simulacao/Pagamento";
import Login from "@/pages/cliente/areaAberta/login/Login";
import Cadastro from "@/pages/cliente/areaAberta/cadastro";
import LinkPagamento from "@/pages/common/simulacao/LinkPagamento";

const AreaAbertaRoutes = {
  path: "app",
  element: <MinimalLayout />,
  children: [
    {
      path: "",
      element: <SimulacaoClienteSemLogin />,
    },

    {
      path: "login",
      element: <Login />,
    },
    {
      path: "simulacao",
      element: <SimulacaoClienteSemLogin />,
    },
    {
      path: "simulacao/parceiro/:company/operador/:usuarioSistema",
      element: <SimulacaoClienteSemLogin />,
    },
    /*{
      path: "simulacao/parceiro/:company/operador/:usuarioSistema/etapa/:etapa",
      element: <Inicio />,
    },
    {
      path: "simulacao/pedido/:idRequest/:tokenRequest",
      element: <Simulacao />,
    },*/
    {
      path: "simulacao/:tokenSimulacao",
      element: <SimulacaoClienteSemLogin />,
    },
    {
      path: "indicacao/:tokenIndicacao",
      element: <SimulacaoClienteSemLogin />,
    },
    {
      path: "simulacao/:idRequest/:tokenRequest/etapa/:etapa",
      element: <SimulacaoClienteSemLogin />,
    },
    {
      path: "simulacao/etapa/:etapa",
      element: <SimulacaoClienteSemLogin />,
    },
    {
      path: "pagamento/:idRequest/:token",
      element: <Pagamento />,
    },
    {
      path: "pagamento/:idRequest/:token/:gateway",
      element: <Pagamento />,
    },
    {
      path: "pagamento/erro/:idRequest/:token",
      element: <Pagamento />,
    },
    {
      path: "pagamento/erro/:idRequest/:token/:gateway",
      element: <Pagamento />,
    },
    {
      path: "confirmacao/:idRequest/:token",
      element: <Finalizacao />,
    },
    {
      path: "recuperar-senha/:token",
      element: <RecuperarSenha />,
    },
    {
      path: "esqueci-minha-senha",
      element: <EsqueciMinhaSenha />,
    },
    {
      path: "cadastro/:idRequest/:token",
      element: <Cadastro />,
    },
    {
      path: "cadastro",
      element: <Cadastro />,
    } /*
    {
      path: "docs/:token",
      element: <LoginDocumentacao />,
    },
    {
      path: "biometria/:token/:cpfCnpj",
      element: <Documentacao />,
    },
    {
      path: "biometria-pedido/:idRequest/:tokenRequest/:token",
      element: <Documentacao />,
    },
    {
      path: "biometria-pedido/:idRequest/:tokenRequest",
      element: <Documentacao />,
    },
    {
      path: "biometria-pedido",
      element: <Documentacao />,
    },

    {
      path: "foto-camera",
      element: <FotoCamera />,
    },*/,
    {
      path: "resgatar-cupom/:token",
      element: <ResgatarCupom />,
    },
    {
      path: "link-pagamento",
      element: <LinkPagamento />,
    },
    {
      path: "*",
      element: <SimulacaoClienteSemLogin />,
    },
  ],
};

export default AreaAbertaRoutes;
