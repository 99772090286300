import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
export default function Logo() {
  return (
    <Grid
      container
      spacing={0}
      style={{ marginTop: "3rem", cursor: "pointer" }}
      onClick={() => {
        window.open(
          "https://api.whatsapp.com/send?phone=5555999042233&text=Ol%C3%A1. Preciso de ajuda com o portal!"
        );
      }}
    >
      <Grid item xs={12}>
        <Typography align="center">Dúvidas?</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center">Entre em contato conosco:</Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <img src="/whatsapp.png" style={{ width: "50px" }} alt=""></img>
      </Grid>
    </Grid>
  );
}
