import { Box, useMediaQuery } from "@mui/material";

import LogoBranco from "@/components/LogoBranco";
import Profile from "./Profile";
import MobileSection from "./MobileSection";
import { useNavigate } from "react-router-dom";

const HeaderContent = () => {
  const navigation = useNavigate();
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const inicio = () => {
    navigation("/app/area-cliente/inicio");
  };

  return (
    <>
      {!matchesXs && (
        <Box
          sx={{ width: "100%", ml: { xs: 0, md: 1 } }}
          style={{ paddingTop: "6px" }}
        >
          <LogoBranco
            onClick={inicio}
            style={{ height: "30px", cursor: "pointer" }}
          />
        </Box>
      )}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
