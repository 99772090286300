import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { get, post } from "@/services/api";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import { apenasNumeros } from "@/services/utils";
import { initialValuesForm } from "../../../common/camposUsuario";
import InputLabel from "@mui/material/InputLabel";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getExterno } from "@/services/apiExterna";
import { setInfoToaster } from "@/store/reducers/geral";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
export default function DadosPessoais() {
  const dispatch = useDispatch();
  const [carregar, setCarregar] = useState(false);

  const Schema = Yup.object().shape({
    name: Yup.string().when("personType", {
      is: "PJ",
      then: Yup.string().required("Informe a razão social"),
    }),
    firstName: Yup.string().when("personType", {
      is: "PF",
      then: Yup.string().required("Informe o primeiro nome"),
    }),
    lastName: Yup.string().when("personType", {
      is: "PF",
      then: Yup.string().required("Informe o sobrenome"),
    }),

    whatsapp: Yup.string()
      .test("len", "Celular inválido", (val) => {
        return apenasNumeros(val).length === 11;
      })
      .required("Informe o celular"),
    email: Yup.string().email("E-mail inválido").required("Informe o e-mail"),
    zipCode: Yup.string()
      .nullable()
      .required("Informe o CEP")
      .test("len", "CEP inválido", (val) => {
        return apenasNumeros(val).length === 8;
      }),
    street: Yup.string().nullable().required("Informe a rua"),
    number: Yup.string().nullable().required("Informe a número"),
    neighborhood: Yup.string().nullable().required("Informe o bairro"),
    city: Yup.string().nullable().required("Informe a cidade"),
    province: Yup.string().nullable().required("Informe o estado"),
  });

  const handleChangeCep = async (value) => {
    formik.setFieldValue("zipCode", value);
    const zipCode = (value || "").replace(/\D/g, "");
    if (zipCode.length !== 8 || formik.values.street) {
      return;
    }
    dispatch(activeSpinner());
    try {
      const url = `https://viacep.com.br/ws/${zipCode}/json/`;
      let request = await getExterno(url);
      dispatch(cancelSpinner());
      if (request && request.logradouro) {
        formik.setFieldValue("neighborhood", request.bairro);
        formik.setFieldValue("street", request.logradouro);
        formik.setFieldValue("city", request.localidade);
        formik.setFieldValue("province", request.uf);
      }
    } catch (e) {
      console.log(e);
      dispatch(cancelSpinner());
    }
  };

  const formik = useFormik({
    initialValues: initialValuesForm,
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        values = { ...values };
        values.whatsapp = apenasNumeros(values.whatsapp);
        values.zipCode = apenasNumeros(values.zipCode);
        values.cpf = apenasNumeros(values.cpf);
        values.cnpj = apenasNumeros(values.cnpj);

        dispatch(activeSpinner());

        const result = await post("secure/user", values);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        dispatch(
          setInfoToaster({
            open: true,
            message: "Dados alterados com sucesso!",
            type: "success",
          })
        );
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao carregar. Tente novamente em instantes!",
          })
        );
      }
    },
  });

  useEffect(() => {
    const carregarDados = async () => {
      try {
        dispatch(activeSpinner());
        let result = await get(`secure/user`);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(
            setInfoDialog({
              open: true,
              descricao: result.error,
            })
          );
          return;
        }
        const dados = result.data;
        dados.zipCode = dados.zipCode || "";
        dados.street = dados.street || "";
        dados.number = dados.number || "";
        dados.complement = dados.complement || "";
        dados.city = dados.city || "";
        dados.neighborhood = dados.neighborhood || "";
        dados.province = dados.province || "";

        formik.setValues(dados);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao carregar! Tente novamente em instantes.",
          })
        );
      }
    };
    if (carregar) {
      setCarregar(false);
      carregarDados();
    }
  }, [carregar, dispatch, formik]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  return (
    <Container>
      <Typography variant="h5" style={{ marginBottom: "1rem" }}>
        MEUS DADOS:
      </Typography>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 3 } }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {formik.values.personType === "PF" && (
              <>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="Primeiro nome"
                    fullWidth
                    variant="standard"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Sobrenome"
                    fullWidth
                    autoComplete="off"
                    variant="standard"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
              </>
            )}
            {formik.values.personType === "PJ" && (
              <Grid item xs={12} md={4}>
                <TextField
                  id="name"
                  name="name"
                  label="Razão social"
                  fullWidth
                  variant="standard"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              {formik.values.personType === "PF" && (
                <InputMask
                  mask="999.999.999-99"
                  name="cpf"
                  disabled
                  value={formik.values.cpf}
                  onChange={formik.handleChange}
                >
                  {() => (
                    <TextField
                      id="cpf"
                      name="cpf"
                      label="CPF"
                      disabled
                      fullWidth
                      variant="standard"
                      error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                      helperText={formik.touched.cpf && formik.errors.cpf}
                    />
                  )}
                </InputMask>
              )}
              {formik.values.personType !== "PF" && (
                <InputMask
                  mask="99.999.999/9999-99"
                  name="cnpj"
                  disabled
                  value={formik.values.cnpj}
                  onChange={formik.handleChange}
                >
                  {() => (
                    <TextField
                      id="cnpj"
                      name="cnpj"
                      label="CNPJ"
                      fullWidth
                      variant="standard"
                      error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                      helperText={formik.touched.cnpj && formik.errors.cnpj}
                    />
                  )}
                </InputMask>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <InputMask
                mask="(99) 9 9999-9999"
                value={formik.values.whatsapp}
                name="whatsapp"
                onChange={formik.handleChange}
              >
                {() => (
                  <TextField
                    id="whatsapp"
                    name="whatsapp"
                    label="Telefone/WhatsApp"
                    fullWidth
                    autoComplete="off"
                    variant="standard"
                    error={
                      formik.touched.whatsapp && Boolean(formik.errors.whatsapp)
                    }
                    helperText={
                      formik.touched.whatsapp && formik.errors.whatsapp
                    }
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="email"
                name="email"
                label="E-mail"
                fullWidth
                autoComplete="off"
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12} md={3}>
                <InputMask
                  mask="99999-999"
                  name="zipCode"
                  value={formik.values.zipCode}
                  onChange={(event) => handleChangeCep(event.target.value)}
                >
                  {() => (
                    <TextField
                      id="zipCode"
                      name="zipCode"
                      label="CEP"
                      fullWidth
                      autoComplete="off"
                      variant="standard"
                      error={
                        formik.touched.zipCode && Boolean(formik.errors.zipCode)
                      }
                      helperText={
                        formik.touched.zipCode && formik.errors.zipCode
                      }
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="street"
                name="street"
                label="Rua"
                fullWidth
                autoComplete="off"
                variant="standard"
                value={formik.values.street}
                onChange={formik.handleChange}
                error={formik.touched.street && Boolean(formik.errors.street)}
                helperText={formik.touched.street && formik.errors.street}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="number"
                name="number"
                label="Número"
                fullWidth
                autoComplete="off"
                variant="standard"
                value={formik.values.number}
                onChange={formik.handleChange}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="complement"
                name="complement"
                label="Complemento"
                fullWidth
                autoComplete="off"
                variant="standard"
                value={formik.values.complement}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="neighborhood"
                name="neighborhood"
                label="Bairro"
                fullWidth
                autoComplete="off"
                variant="standard"
                value={formik.values.neighborhood}
                onChange={formik.handleChange}
                error={
                  formik.touched.neighborhood &&
                  Boolean(formik.errors.neighborhood)
                }
                helperText={
                  formik.touched.neighborhood && formik.errors.neighborhood
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="city"
                name="city"
                label="Cidade"
                fullWidth
                autoComplete="off"
                variant="standard"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel shrink id="label-select-province">
                UF
              </InputLabel>
              <Select
                labelId="label-select-province"
                id="province"
                name="province"
                label="UF"
                fullWidth
                autoComplete="off"
                variant="standard"
                value={formik.values.province}
                error={
                  formik.touched.province && Boolean(formik.errors.province)
                }
                onChange={(event) => {
                  formik.setFieldValue("province", event.target.value);
                }}
              >
                <MenuItem value="AC">AC</MenuItem>
                <MenuItem value="AL">AL</MenuItem>
                <MenuItem value="AP">AP</MenuItem>
                <MenuItem value="AM">AM</MenuItem>
                <MenuItem value="BA">BA</MenuItem>
                <MenuItem value="CE">CE</MenuItem>
                <MenuItem value="ES">ES</MenuItem>
                <MenuItem value="GO">GO</MenuItem>
                <MenuItem value="MA">MA</MenuItem>
                <MenuItem value="MT">MT</MenuItem>
                <MenuItem value="MS">MS</MenuItem>
                <MenuItem value="MG">MG</MenuItem>
                <MenuItem value="PA">PA</MenuItem>
                <MenuItem value="PB">PB</MenuItem>
                <MenuItem value="PR">PR</MenuItem>
                <MenuItem value="PE">PE</MenuItem>
                <MenuItem value="PI">PI</MenuItem>
                <MenuItem value="RJ">RJ</MenuItem>
                <MenuItem value="RN">RN</MenuItem>
                <MenuItem value="RS">RS</MenuItem>
                <MenuItem value="RO">RO</MenuItem>
                <MenuItem value="RR">RR</MenuItem>
                <MenuItem value="SC">SC</MenuItem>
                <MenuItem value="SP">SP</MenuItem>
                <MenuItem value="SE">SE</MenuItem>
                <MenuItem value="TO">TO</MenuItem>
                <MenuItem value="DF">DF</MenuItem>
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {formik.touched.province && formik.errors.province}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormControlLabel
                onChange={() => {
                  formik.setFieldValue(
                    "aceitaEmail",
                    !formik.values.aceitaEmail
                  );
                }}
                name="aceitaEmail"
                value={!!formik.values.aceitaEmail}
                control={<Checkbox checked={!!formik.values.aceitaEmail} />}
                label="Aceito receber avisos por e-mail"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                onChange={() => {
                  formik.setFieldValue("aceitaPush", !formik.values.aceitaPush);
                }}
                name="aceitaPush"
                value={!!formik.values.aceitaPush}
                control={<Checkbox checked={!!formik.values.aceitaPush} />}
                label="Aceito receber avisos por Push"
              />
            </Grid>
          </Grid>{" "}
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <Box>
                <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
                  ALTERAR DADOS
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
