import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { get } from "@/services/api";
import AlertTitle from "@mui/material/AlertTitle";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Logo from "@/components/Logo";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";

export default function Finalizacao() {
  const { idRequest, token } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [carregar, setCarregar] = React.useState(false);
  const [dadosUsuario, setDadosUsuario] = React.useState({});

  useEffect(() => {
    const carregarDados = async () => {
      try {
        dispatch(activeSpinner());
        const result = await get(`public/request-info/${idRequest}/${token}`);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        setDadosUsuario(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao consultar seus dados. Por favor atualize a página!",
          })
        );
      }
    };
    if (carregar && token && idRequest) {
      setCarregar(false);
      carregarDados();
      return;
    }
  }, [carregar, dispatch, token, idRequest]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} align="center">
            <Logo></Logo>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="success" style={{ width: "100%" }}>
              <AlertTitle>Sucesso</AlertTitle>
              <Typography>Pagamento realizado com sucesso. </Typography>
              <Typography>
                O pedido passará por uma análise de segurança.
              </Typography>
              <Typography>
                Em breve o cliente receberá por e-mail os detalhes da
                contratação.
              </Typography>
              <Typography>
                ID do pedido: <b>{dadosUsuario.idRequestView}</b>
              </Typography>
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="text"
              onClick={() => {
                navigation("/dashboard");
              }}
            >
              VOLTAR AO INÍCIO
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
