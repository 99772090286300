import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { setInfoToaster } from "@/store/reducers/geral";
export default function LinkCashback({ indicationLink }) {
  const dispatch = useDispatch();
  return (
    <>
      {indicationLink && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                Compartilhe o link abaixo com seus amigos.
              </Typography>
              <Typography>
                Quando seu amigo realizar uma contratação vocês dois receberão{" "}
                <b>R$ 15,00</b> de <b>Cashback</b> para utilizar na próxima
                contratação!
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{ wordBreak: "break-all" }}
                onClick={() => {
                  navigator.clipboard.writeText(indicationLink);
                  dispatch(
                    setInfoToaster({
                      open: true,
                      message: "Copiado",
                      type: "success",
                    })
                  );
                }}
              >
                <b>{indicationLink}</b>
              </Typography>
              <Button
                variant="text"
                component="label"
                startIcon={<ContentCopyIcon />}
                onClick={() => {
                  navigator.clipboard.writeText(indicationLink);
                  dispatch(
                    setInfoToaster({
                      open: true,
                      message: "Copiado",
                      type: "success",
                    })
                  );
                }}
              >
                COPIAR LINK
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
