import { useRoutes } from "react-router-dom";

// project import
import AreaAbertaParceiroRoutes from "./AreaAbertaParceiroRoutes";
import AreaAbertaRoutes from "./AreaAbertaRoutes";
import DashboardParceiroRoutes from "./DashboardParceiroRoutes";
import GeralRoutes from "./GeralRoutes";
import DashboardRoutes from "./DashboardRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    DashboardParceiroRoutes,
    AreaAbertaRoutes,
    AreaAbertaParceiroRoutes,
    GeralRoutes,
    DashboardRoutes,
  ]);
}
