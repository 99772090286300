import * as React from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import ContatoWhatsApp from "@/components/ContatoWhatsApp";
import Logo from "@/components/Logo";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { post } from "@/services/api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";

export default function RecuperarSenha() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [showMensagemSucesso, setShowMensagemSucesso] = React.useState(false);
  const navigation = useNavigate();

  const Schema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Informe sua nova senha")
      .test("len", "A senha deve conter pelo menos 6 digítos", (val) => {
        return (val || "").toString().length >= 6;
      }),
    confirmPassword: Yup.string()
      .required("Informe a confirmação")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Senha e confirmação estão diferentes"
      ),
  });
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        dispatch(activeSpinner());
        const valuesRecover = {
          token: token,
          password: values.newPassword,
        };
        const result = await post("public/recover-password", valuesRecover);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        setShowMensagemSucesso(true);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({ open: true, descricao: "Ocorreu um erro ao salvar" })
        );
      }
    },
  });

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid container style={{ marginBottom: "1rem" }}>
          <Grid item xs={12} align="center">
            <Logo></Logo>
          </Grid>
        </Grid>
        <Typography variant="h6" align="center">
          Informe sua nova senha:
        </Typography>
        {showMensagemSucesso && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Alert severity="success" style={{ width: "100%" }}>
                <AlertTitle>Sucesso</AlertTitle>
                Senha alterada com sucesso!
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <>
                  <Button
                    variant="contained"
                    type="button"
                    fullWidth
                    onClick={() => {
                      navigation("/app/login");
                    }}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    IR PARA TELA DE LOGIN
                  </Button>
                </>
              </Box>
            </Grid>
          </Grid>
        )}
        {!showMensagemSucesso && (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} style={{ marginTop: "0.5rem" }}>
              <Grid item xs={12}>
                <TextField
                  label="Nova senha"
                  type="password"
                  autoComplete="current-password"
                  fullWidth
                  variant="standard"
                  value={formik.values.newPassword}
                  onChange={(event) => {
                    formik.setFieldValue("newPassword", event.target.value);
                  }}
                  error={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirme a senha"
                  type="password"
                  autoComplete="current-password"
                  fullWidth
                  variant="standard"
                  value={formik.values.confirmPassword}
                  onChange={(event) => {
                    formik.setFieldValue("confirmPassword", event.target.value);
                  }}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{ mt: 3, ml: 1 }}
                  >
                    ALTERAR SENHA
                  </Button>
                </>
              </Box>
            </Grid>
          </form>
        )}
      </Paper>
      <ContatoWhatsApp></ContatoWhatsApp>
    </Container>
  );
}
