import * as React from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Logo from "@/components/Logo";
import AvisoAntiFraude from "./AvisoAntiFraude";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { retornaUrlApp } from "@/services/utils";
import { formatMonetario } from "@/services/utils";
import * as Yup from "yup";
import ShareIcon from "@mui/icons-material/Share";
import Alert from "@mui/material/Alert";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { apenasNumeros, apenasMonetario } from "@/services/utils";
import { initialValuesForm } from "../camposUsuario";
import { useDispatch } from "react-redux";
import { setInfoToaster } from "@/store/reducers/geral";

export default function DadosPessoais({
  salvarSimulacao,
  setNavegarEtapa,
  dadosUsuario,
  carregarEtapa,
  setCarregarEtapa,
  setDadosUsuario,
  company,
  limparDados,
  ehParceiro,
  abrirLinkPagamento,
}) {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const Schema = Yup.object().shape({
    billValue: Yup.number().required("Informe o valor da simulação"),
  });
  const asteriscoCamposObrigatorios = () => {
    if (formik.values.acessoCliente) {
      return "*";
    }
    return "";
  };
  const salvar = async (values) => {
    values = { ...values };
    values.whatsapp = apenasNumeros(values.whatsapp);
    values.billValue = apenasMonetario(values.billValue);
    console.log(values);
    return await salvarSimulacao(values);
  };
  const ativarAntiFraude = () => {
    dispatch(
      setInfoToaster({
        open: true,
        message: "Anti-fraude ativado com sucesso!",
        type: "success",
      })
    );
    formik.setFieldValue("antiFraudActive", !formik.values.antiFraudActive);
  };

  const registraEventoTaboola = () => {
    try {
      window._tfa.push({ notify: "event", name: "lead", id: 1511171 });
    } catch (e) {
      console.log(e);
    }
  };

  const formik = useFormik({
    initialValues: initialValuesForm,
    validationSchema: Schema,
    onSubmit: async (values) => {
      registraEventoTaboola();
      const result = await salvar(values);
      setDadosUsuario(result);
      if (values?.infoPartner?.total > 0) {
        if (values.infoPartner?.informedPartnerRequestBills) {
          setNavegarEtapa(5);
        } else {
          setNavegarEtapa(3);
        }
      } else {
        setNavegarEtapa(2);
      }
    },
  });

  useEffect(() => {
    if (!carregarEtapa) {
      return;
    }
    console.log("dadosUsuario", dadosUsuario);
    setCarregarEtapa(false);
    formik.setValues(dadosUsuario);
  }, [carregarEtapa, setCarregarEtapa, dadosUsuario, formik]);

  const camposInvalidos = () => {
    return Object.values(formik.errors).length > 0 && formik.submitCount > 0;
  };

  const criarLink = async () => {
    const simulacao = formik.values;
    let requestLink = "";
    if (simulacao.idCompany) {
      requestLink = `${retornaUrlApp()}app/simulacao/parceiro/${
        simulacao.idCompany
      }/operador/${simulacao.idUserSystem}`;
    } else {
      requestLink = retornaUrlApp() + "app/simulacao";
    }
    abrirLinkPagamento({ requestLink: requestLink });
  };

  const limparDadosEtapa = () => {
    formik.resetForm();
    limparDados();
  };

  return (
    <React.Fragment>
      <Grid item xs={12} align="center">
        <Logo {...{ maxWidth: "120px" }}></Logo>
      </Grid>
      <Typography align="center" sx={{ mb: 1 }}>
        Simule e solicite o parcelamento agora mesmo:
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {!formik.values?.infoPartner?.billValue && (
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                name="billValue"
                label={"Valor total da simulação *"}
                fullWidth
                onWheel={() => document.activeElement.blur()}
                variant="standard"
                placeholder="R$ 0,00"
                value={formik.values.billValue}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={
                  formik.touched.billValue && Boolean(formik.errors.billValue)
                }
                helperText={formik.touched.billValue && formik.errors.billValue}
              />
            </Grid>
          )}

          {camposInvalidos() && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <Typography>
                  Verifique se os campos obrigatórios foram preenchidos
                  corretamente!
                </Typography>
              </Alert>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            {!formik.values?.infoPartner?.billValue && (
              <Box>
                <Button
                  onClick={() => {
                    limparDadosEtapa();
                  }}
                  sx={{ mt: 3, ml: 1 }}
                >
                  LIMPAR DADOS
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                id="btn-avancar-dados-pessoais"
                type="submit"
                sx={{ mt: 3, ml: 1 }}
              >
                PRÓXIMO
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
