import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, Toolbar, useMediaQuery } from "@mui/material";
import Spinner from "@/components/Spinner";
import Toaster from "@/components/Toaster";
import DialogFormatoRelatorio from "@/components/DialogFormatoRelatorio";
import Dialog from "@/components/Dialog";
import Drawer from "./Drawer";
import { useTheme } from "@mui/material/styles";
import Header from "./Header";
import navigation from "@/menu-items-parceiro";
import DialogConfirmacao from "@/components/DialogConfirmacao";
import Breadcrumbs from "@/components/@extended/Breadcrumbs";
import { openDrawer } from "@/store/reducers/menu";
import {
  setInfoDialog,
  setInfoDialogConfirmacao,
  setInfoDialogFormatoRelatorio,
} from "@/store/reducers/geral";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const geral = useSelector((state) => state.geral);
  const { drawerOpen } = useSelector((state) => state.menu);

  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG, dispatch]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}
      >
        <Toolbar />
        <Breadcrumbs
          navigation={navigation}
          title
          titleBottom
          card={false}
          divider={false}
        />
        <Outlet />
      </Box>
      {geral?.infoDialog?.open && (
        <Dialog {...{ infoDialog: geral.infoDialog, setInfoDialog }} />
      )}
      <Spinner {...{ showSpinner: geral.showSpinner }}></Spinner>
      <Toaster {...{ infoToaster: geral.infoToaster }}></Toaster>
      {geral?.infoDialogConfirmacao.open && (
        <DialogConfirmacao
          {...{
            infoDialogConfirmacao: geral.infoDialogConfirmacao,
            setInfoDialogConfirmacao,
          }}
        />
      )}
      {geral?.infoDialogFormatoRelatorio.open && (
        <DialogFormatoRelatorio
          {...{
            infoDialogFormatoRelatorio: geral.infoDialogFormatoRelatorio,
            setInfoDialogFormatoRelatorio,
          }}
        />
      )}
    </Box>
  );
};

export default MainLayout;
