import { createTheme } from "@mui/material/styles";
export default createTheme({
  palette: {
    background: {
      default: "#fafaf3",
    },
    primary: {
      light: "#009b97",
      main: "#009b97",
      dark: "#009b97",
      contrastText: "#ffffff",
    },
  },
});
