import * as React from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import ShareIcon from "@mui/icons-material/Share";
import { useFormik } from "formik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { apenasNumeros } from "@/services/utils";
import Alert from "@mui/material/Alert";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { getExterno } from "@/services/apiExterna";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
export default function EtapaConfirmacao({
  permiteVoltarEtapa,
  salvarSimulacao,
  salvarSimulacaoParaLink,
  setNavegarEtapa,
  dadosUsuario,
  carregarEtapa,
  abrirLinkPagamento,
  setCarregarEtapa,
  ehParceiro,
}) {
  const dispatch = useDispatch();

  const navigation = useNavigate();
  const Schema = Yup.object().shape({
    name: Yup.string().when("personType", {
      is: "PF",
      then: Yup.string().nullable().required("Informe o nome"),
    }),

    whatsapp: Yup.string()
      .test("len", "Celular inválido", (val) => {
        return apenasNumeros(val).length === 11;
      })
      .required("Informe seu celular"),
    email: Yup.string().email("E-mail inválido").required("Informe seu e-mail"),
    cnpj: Yup.string().when("personType", {
      is: "PJ",
      then: Yup.string()
        .required("Informe o CNPJ")
        .test("len", "CNPJ inválido", (val) => {
          return (val || "").toString().replace(/\D/g, "").length === 14;
        }),
    }),

    cpf: Yup.string().when("personType", {
      is: "PF",
      then: Yup.string()
        .required("Informe o CPF")
        .test("len", "CPF inválido", (val) => {
          return apenasNumeros(val).length === 11;
        }),
    }),
    zipCode: Yup.string()
      .nullable()
      .required("Informe o CEP")
      .test("len", "CEP inválido", (val) => {
        return apenasNumeros(val).length === 8;
      }),
    street: Yup.string().nullable().required("Informe a rua"),
    number: Yup.string().nullable().required("Informe a número"),
    neighborhood: Yup.string().nullable().required("Informe o bairro"),
    city: Yup.string().nullable().required("Informe a cidade"),
    province: Yup.string().nullable().required("Informe o estado"),
  });

  const salvar = async (values, gerarLink) => {
    const tokenFCM = localStorage.getItem("tokenFCM");
    if (tokenFCM) {
      values.tokenFCM = tokenFCM;
    }

    const nameSplit = values.name.split(" ");
    values.firstName = nameSplit[0];
    values.lastName = nameSplit[nameSplit.length - 1];
    values.whatsapp = apenasNumeros(values.whatsapp);
    values.cep = apenasNumeros(values.cep);
    values.cpf = apenasNumeros(values.cpf);
    values.cnpj = apenasNumeros(values.cnpj);
    if (gerarLink) {
      return await salvarSimulacaoParaLink(values);
    }
    return await salvarSimulacao(values);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      whatsapp: "",
      province: "",
      howDidYouGetUs: "",
      zipCode: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      name: "",
      personType: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      values.name = values.name.trim();
      if (values.personType === "PF" && values.name.indexOf(" ") === -1) {
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Por favor informe o nome completo com nome e sobrenome!",
          })
        );
        return;
      }
      const requestResult = await salvar(values);
      if (ehParceiro) {
        navigation(
          `/dashboard/pagamento/${requestResult.idRequest}/${requestResult.token}/${values.idCompany}`
        );
      } else {
        navigation(
          `/app/pagamento/${requestResult.idRequest}/${requestResult.token}`
        );
      }
    },
  });

  const camposInvalidos = () => {
    return Object.values(formik.errors).length > 0 && formik.submitCount > 0;
  };

  useEffect(() => {
    if (!carregarEtapa) {
      return;
    }
    setCarregarEtapa(false);
    formik.setValues(dadosUsuario);
  }, [formik, carregarEtapa, setCarregarEtapa, dadosUsuario]);

  const handleChangeCep = async (value) => {
    formik.setFieldValue("zipCode", value);
    const zipCode = (value || "").replace(/\D/g, "");
    if (zipCode.length !== 8 || formik.values.street) {
      return;
    }
    dispatch(activeSpinner());
    try {
      const url = `https://viacep.com.br/ws/${zipCode}/json/`;
      let request = await getExterno(url);
      dispatch(cancelSpinner());
      if (request && request.logradouro) {
        formik.setFieldValue("neighborhood", request.bairro);
        formik.setFieldValue("street", request.logradouro);
        formik.setFieldValue("city", request.localidade);
        formik.setFieldValue("province", request.uf);
      }
    } catch (e) {
      console.log(e);
      dispatch(cancelSpinner());
    }
  };

  const criarLink = async () => {
    const requestResult = await salvar(formik.values, true);
    abrirLinkPagamento(requestResult);
  };

  return (
    <React.Fragment>
      <Typography align="center" sx={{ mb: 2 }}>
        Precisamos de mais alguns dados para finalizar a contratação:
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <RadioGroup
              defaultValue="PF"
              value={formik.values.personType}
              onChange={formik.handleChange}
              row
              name="personType"
            >
              <FormControlLabel
                value="PF"
                control={<Radio />}
                label="Pessoa física"
              />
              <FormControlLabel
                value="PJ"
                control={<Radio />}
                label="Pessoa jurídica"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              mask="(99) 9 9999-9999"
              value={formik.values.whatsapp}
              name="whatsapp"
              onChange={formik.handleChange}
            >
              {() => (
                <TextField
                  id="whatsapp"
                  name="whatsapp"
                  label="Telefone/WhatsApp *"
                  fullWidth
                  autoComplete="tel"
                  variant="standard"
                  error={
                    formik.touched.whatsapp && Boolean(formik.errors.whatsapp)
                  }
                  helperText={formik.touched.whatsapp && formik.errors.whatsapp}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              id="email"
              name="email"
              label="E-mail *"
              fullWidth
              autoComplete="email"
              variant="standard"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            {formik.values.personType === "PF" && (
              <InputMask
                mask="999.999.999-99"
                name="cpf"
                value={formik.values.cpf}
                onChange={formik.handleChange}
              >
                {() => (
                  <TextField
                    id="cpf"
                    name="cpf"
                    label="CPF *"
                    variant="standard"
                    error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                    helperText={formik.touched.cpf && formik.errors.cpf}
                  />
                )}
              </InputMask>
            )}
            {formik.values.personType !== "PF" && (
              <InputMask
                mask="99.999.999/9999-99"
                name="cnpj"
                value={formik.values.cnpj}
                onChange={formik.handleChange}
              >
                {() => (
                  <TextField
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ *"
                    variant="standard"
                    error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                    helperText={formik.touched.cnpj && formik.errors.cnpj}
                  />
                )}
              </InputMask>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              name="name"
              label={
                formik.values.personType === "PF"
                  ? "Nome completo *"
                  : "Razão social *"
              }
              fullWidth
              autoComplete="given-name"
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>

          <Grid item xs={12}>
            <InputMask
              mask="99999-999"
              name="zipCode"
              value={formik.values.zipCode}
              onChange={(event) => handleChangeCep(event.target.value)}
            >
              {() => (
                <TextField
                  id="zipCode"
                  name="zipCode"
                  label={"CEP *"}
                  variant="standard"
                  error={
                    formik.touched.zipCode && Boolean(formik.errors.zipCode)
                  }
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="street"
              name="street"
              label={"Rua *"}
              fullWidth
              variant="standard"
              value={formik.values.street}
              onChange={formik.handleChange}
              error={formik.touched.street && Boolean(formik.errors.street)}
              helperText={formik.touched.street && formik.errors.street}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="number"
              name="number"
              label={"Número *"}
              fullWidth
              variant="standard"
              value={formik.values.number}
              onChange={formik.handleChange}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={formik.touched.number && formik.errors.number}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="complement"
              name="complement"
              label="Complemento"
              fullWidth
              variant="standard"
              value={formik.values.complement}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="neighborhood"
              name="neighborhood"
              label={"Bairro *"}
              fullWidth
              variant="standard"
              value={formik.values.neighborhood}
              onChange={formik.handleChange}
              error={
                formik.touched.neighborhood &&
                Boolean(formik.errors.neighborhood)
              }
              helperText={
                formik.touched.neighborhood && formik.errors.neighborhood
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="city"
              name="city"
              label={"Cidade *"}
              fullWidth
              variant="standard"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel shrink id="label-select-province">
              {"UF *"}
            </InputLabel>
            <Select
              labelId="label-select-province"
              id="province"
              name="province"
              label={"UF *"}
              fullWidth
              variant="standard"
              value={formik.values.province}
              error={formik.touched.province && Boolean(formik.errors.province)}
              onChange={(event) => {
                formik.setFieldValue("province", event.target.value);
              }}
            >
              <MenuItem value="AC">AC</MenuItem>
              <MenuItem value="AL">AL</MenuItem>
              <MenuItem value="AP">AP</MenuItem>
              <MenuItem value="AM">AM</MenuItem>
              <MenuItem value="BA">BA</MenuItem>
              <MenuItem value="CE">CE</MenuItem>
              <MenuItem value="ES">ES</MenuItem>
              <MenuItem value="GO">GO</MenuItem>
              <MenuItem value="MA">MA</MenuItem>
              <MenuItem value="MT">MT</MenuItem>
              <MenuItem value="MS">MS</MenuItem>
              <MenuItem value="MG">MG</MenuItem>
              <MenuItem value="PA">PA</MenuItem>
              <MenuItem value="PB">PB</MenuItem>
              <MenuItem value="PR">PR</MenuItem>
              <MenuItem value="PE">PE</MenuItem>
              <MenuItem value="PI">PI</MenuItem>
              <MenuItem value="RJ">RJ</MenuItem>
              <MenuItem value="RN">RN</MenuItem>
              <MenuItem value="RS">RS</MenuItem>
              <MenuItem value="RO">RO</MenuItem>
              <MenuItem value="RR">RR</MenuItem>
              <MenuItem value="SC">SC</MenuItem>
              <MenuItem value="SP">SP</MenuItem>
              <MenuItem value="SE">SE</MenuItem>
              <MenuItem value="TO">TO</MenuItem>
              <MenuItem value="DF">DF</MenuItem>
            </Select>
            <FormHelperText style={{ color: "red" }}>
              {formik.touched.province && formik.errors.province}
            </FormHelperText>
          </Grid>

          {camposInvalidos() && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <Typography>
                  Verifique se os campos obrigatórios foram preenchidos
                  corretamente!
                </Typography>
              </Alert>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            {permiteVoltarEtapa() && (
              <Box>
                <Button
                  onClick={() => {
                    setNavegarEtapa(3);
                  }}
                  sx={{ mt: 3, ml: 1 }}
                >
                  VOLTAR
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                id="btn-avancar-confirmacao-dados"
                type="submit"
                sx={{ mt: 3, ml: 1 }}
              >
                IR PARA PAGAMENTO
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
