// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import user from "./user";
import geral from "./geral";
import biometria from "./biometria";
import dashboardParceiro from "./dashboardParceiro";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  user,
  dashboardParceiro,
  geral,
  biometria,
});

export default reducers;
