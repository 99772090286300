import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Logo from "@/components/Logo";
import * as Yup from "yup";
import ShareIcon from "@mui/icons-material/Share";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { useEffect } from "react";
import { formatMonetario, retornaUrlApp } from "@/services/utils";
import { post } from "@/services/api";
import { apenasNumeros } from "@/services/utils";
import Alert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import { get } from "@/services/api";
import Cards from "@/components/credit-card";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
import DialogTermosUso from "./DialogTermosUso";

export default function Pagamento() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { token, idRequest, gateway, company } = useParams();
  const [dadosUsuario, setDadosUsuario] = React.useState({});
  const [avisoCartao, setAvisoCartao] = React.useState({});
  const [focusCreditCard, setFocusCreditCard] = React.useState("");
  const [carregar, setCarregar] = React.useState(false);
  const [processarPagamento, setProcessarPagamento] = React.useState(false);
  const [telaSimulacao, setTelaSimulacao] = React.useState(false);
  const [infoDialogTermosUso, setInfoDialogTermosUso] = React.useState({
    open: false,
  });

  const navigation = useNavigate();

  const Schema = Yup.object().shape({
    expireDate: Yup.string()
      .required("Informe o mês/ano de validade")
      .test("len", "Data de validade inválida", (val) => {
        return (val || "").toString().replace(/\D/g, "").length >= 4;
      }),

    cardName: Yup.string().required("Informe o nome igual do cartão"),
    ccv: Yup.string()
      .required("Informe o CVV")
      .test("len", "CVV inválido", (val) => {
        return apenasNumeros(val).length >= 1;
      }),
    cardNumber: Yup.string().required("Informe o número do cartão"),
  });
  const formik = useFormik({
    initialValues: {
      cardName: "",
      cardNumber: "",
      expireDate: "",
      ccv: "",
    },
    validationSchema: Schema,
    onSubmit: async () => {
      setProcessarPagamento(true);
    },
  });

  useEffect(() => {
    const redirecionaParaErro = (mensagem) => {
      dispatch(cancelSpinner());
      if (gateway) {
        navigation(`/app/pagamento/erro/${idRequest}/${token}/${gateway}`);
      } else {
        if (company) {
          navigation(
            `/dashboard/pagamento/erro/${idRequest}/${token}/${company}`
          );
        } else {
          if (pathname.indexOf("area-cliente") !== -1) {
            navigation(
              `/app/area-cliente/pagamento/erro/${idRequest}/${token}`
            );
          } else {
            navigation(`/app/pagamento/erro/${idRequest}/${token}`);
          }
        }
      }
    };

    const pagar = async () => {
      const values = formik.values;
      if (!values.dataAceiteTermosUso) {
        abrirTermosUso(true);
        return;
      }
      dispatch(activeSpinner());
      setAvisoCartao({});
      try {
        values.token = token;
        values.idRequest = idRequest;
        values.cardNumber = apenasNumeros(values.cardNumber);
        values.ccv = apenasNumeros(values.ccv);
        let expireDate = apenasNumeros(values.expireDate);
        if (expireDate.length === 4) {
          expireDate =
            expireDate.substring(0, 2) + "20" + expireDate.substring(2, 4);
        }
        values.gateway = gateway;
        values.expireDate = expireDate;
        values.deviceId = document.getElementById("deviceId").value;
        values.idSessionClearSale =
          document.getElementById("idSessionClearSale").value;

        const result = await post("public/payment", values);
        dispatch(cancelSpinner());
        if (result.error) {
          redirecionaParaErro(result.error);
          setAvisoCartao({ open: true, descricao: result.error });
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        sessionStorage.setItem(
          "resultadoPagamento",
          JSON.stringify(result.data)
        );
        sessionStorage.removeItem("dadosUsuario");
        if (company) {
          navigation(`/dashboard/confirmacao/${idRequest}/${token}/parceiro`);
        } else if (result.data.type === "Auto-atendimento") {
          navigation(`/app/confirmacao/${idRequest}/${token}`);
        } else {
          navigation(`/app/area-cliente/confirmacao/${idRequest}/${token}`);
        }
      } catch (e) {
        console.log(e);

        redirecionaParaErro(
          "Ocorreu um erro ao processar. Tente novamente em instantes."
        );
        setAvisoCartao({
          open: true,
          descricao:
            "Ocorreu um erro ao processar. Tente novamente em instantes.",
        });
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao processar. Tente novamente em instantes.",
          })
        );
      }
    };
    if (processarPagamento) {
      setProcessarPagamento(false);
      pagar();
    }
  }, [
    processarPagamento,
    dispatch,
    navigation,
    formik,
    gateway,
    idRequest,
    company,
    token,
  ]);

  const abrirTermosUso = (acaoPagamento) => {
    setInfoDialogTermosUso({
      open: true,
      acaoPagamento: acaoPagamento,
    });
  };
  const aceitaTermosUso = () => {
    formik.setFieldValue("dataAceiteTermosUso", new Date());
    console.log(
      "infoDialogTermosUso.acaoPagamento",
      infoDialogTermosUso.acaoPagamento
    );
    if (infoDialogTermosUso.acaoPagamento) {
      setProcessarPagamento(true);
    }
  };

  useEffect(() => {
    const carregarDados = async () => {
      try {
        dispatch(activeSpinner());
        const result = await get(`public/request-info/${idRequest}/${token}`);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        setDadosUsuario(result);
        if (!!sessionStorage.getItem("dadosUsuario")) {
          setTelaSimulacao(true);
        }
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao consultar seus dados. Por favor atualize a página!",
          })
        );
      }
    };
    if (carregar && idRequest && token) {
      setCarregar(false);
      carregarDados();
      return;
    }
  }, [carregar, dispatch, navigation, idRequest, token]);

  const criarLink = async () => {
    const dadosSimulacao = {};
    dadosSimulacao.idRequest = dadosUsuario.idRequestView;
    dadosSimulacao.paymentLink = `${retornaUrlApp()}app/pagamento/${idRequest}/${token}`;

    dadosSimulacao.billValue = dadosUsuario.billValue;
    sessionStorage.setItem("dadosSimulacao", JSON.stringify(dadosSimulacao));
    if (company) {
      navigation(`/dashboard/link-pagamento`);
    } else {
      navigation(`/app/link-pagamento`);
    }
  };

  useEffect(() => {
    setCarregar(true);
  }, []);

  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{ maxWidth: "500px" }}
      sx={{ mb: 4 }}
    >
      <Paper
        variant="outlined"
        sx={{ my: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }}
      >
        <Grid container>
          <Grid item xs={12} align="center" sx={{ m: 1 }}>
            <Button
              variant="outlined"
              type="button"
              startIcon={<ShareIcon />}
              onClick={() => {
                criarLink();
              }}
            >
              SALVAR LINK DO PAGAMENTO
            </Button>
          </Grid>
          <Grid item xs={12} align="center">
            <Logo {...{ maxWidth: "80px" }}></Logo>
          </Grid>
        </Grid>

        <Alert severity="info" style={{ marginBottom: "1rem" }}>
          <Typography>
            Informe os dados do cartão para finalizar a contratação!
          </Typography>
        </Alert>

        <div id="PaymentForm">
          <Cards
            cvc={formik.values.ccv}
            expiry={formik.values.expireDate}
            focused={focusCreditCard}
            name={formik.values.cardName}
            number={formik.values.cardNumber}
          />
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} style={{ marginTop: "0.2rem" }}>
              {avisoCartao.open && (
                <Grid item xs={12}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setAvisoCartao({ open: false });
                    }}
                  >
                    {avisoCartao.descricao}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                <InputMask
                  mask="9999 9999 9999 9999"
                  name="cardNumber"
                  value={formik.values.cardNumber}
                  onChange={formik.handleChange}
                >
                  {() => (
                    <TextField
                      id="cardNumber"
                      name="cardNumber"
                      label="Número do cartão"
                      fullWidth
                      autoComplete="off"
                      variant="standard"
                      error={
                        formik.touched.cardNumber &&
                        Boolean(formik.errors.cardNumber)
                      }
                      helperText={
                        formik.touched.cardNumber && formik.errors.cardNumber
                      }
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="cardName"
                  label="Nome igual ao do cartão"
                  value={formik.values.cardName}
                  onChange={formik.handleChange}
                  onFocus={(e) => {
                    setFocusCreditCard("name");
                  }}
                  inputProps={{
                    maxLength: 25,
                    style: { textTransform: "uppercase" },
                  }}
                  fullWidth
                  autoComplete="cc-name"
                  variant="standard"
                  error={
                    formik.touched.cardName && Boolean(formik.errors.cardName)
                  }
                  helperText={formik.touched.cardName && formik.errors.cardName}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputMask
                  mask="99/9999"
                  name="expireDate"
                  value={formik.values.expireDate}
                  onChange={formik.handleChange}
                  onFocus={(e) => {
                    setFocusCreditCard("expiry");
                  }}
                >
                  {() => (
                    <TextField
                      id="expireDate"
                      name="expireDate"
                      label="Validade (Ex: 10/2028)"
                      fullWidth
                      autoComplete="off"
                      placeholder="MM/AAAA"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        formik.touched.expireDate &&
                        Boolean(formik.errors.expireDate)
                      }
                      helperText={
                        formik.touched.expireDate && formik.errors.expireDate
                      }
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  id="ccv"
                  label="CVV"
                  value={formik.values.ccv}
                  onChange={formik.handleChange}
                  onFocus={(e) => {
                    setFocusCreditCard("cvc");
                  }}
                  fullWidth
                  autoComplete="cc-csc"
                  variant="standard"
                  error={formik.touched.ccv && Boolean(formik.errors.ccv)}
                  helperText={formik.touched.ccv && formik.errors.ccv}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 3, ml: 1 }}>
                <Typography
                  sx={{ mb: 1 }}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    abrirTermosUso();
                  }}
                >
                  Clique aqui para ler os <b>termos de uso</b> e{" "}
                  <b>política de privacidade</b>.
                </Typography>
                <Typography>
                  Você pagará em <b>{dadosUsuario.installments}</b>{" "}
                  {dadosUsuario.installments === 1 ? "vez" : "vezes"} de{" "}
                  <b>{formatMonetario(dadosUsuario.installmentValue)}</b>.
                </Typography>
                <Typography>
                  Valor total transacionado de{" "}
                  <b>{formatMonetario(dadosUsuario.total)}</b>.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <>
                  <Button
                    variant="contained"
                    type="submit"
                    id="btn-avancar-pagamento"
                    fullWidth
                    sx={{ mt: 1, ml: 1 }}
                  >
                    PAGAR AGORA
                  </Button>
                  {telaSimulacao && (
                    <Button
                      variant="text"
                      type="button"
                      fullWidth
                      onClick={() => {
                        window.location.href = dadosUsuario.requestLink;
                      }}
                      sx={{ mt: 1, ml: 1 }}
                    >
                      VOLTAR
                    </Button>
                  )}
                </>
              </Box>
            </Grid>
          </form>
        </div>
      </Paper>
      {infoDialogTermosUso?.open && (
        <DialogTermosUso
          {...{ infoDialogTermosUso, setInfoDialogTermosUso, aceitaTermosUso }}
        />
      )}
    </Container>
  );
}
