import * as React from "react";
import Container from "@mui/material/Container";
import { apenasNumeros } from "@/services/utils";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ContatoWhatsApp from "@/components/ContatoWhatsApp";
import Logo from "@/components/Logo";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { post } from "@/services/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
export default function Pagamento() {
  const [showMensagemSucesso, setShowMensagemSucesso] = React.useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const Schema = Yup.object().shape({
    cpfCnpj: Yup.string().required("Informe o CPF ou CNPJ"),
  });
  const formik = useFormik({
    initialValues: {
      cpfCnpj: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        dispatch(activeSpinner());
        const result = await post("public/forgot-password", {
          cpfCnpj: apenasNumeros(values.cpfCnpj),
          idRequestBind: sessionStorage.getItem("idRequestBind"),
          tokenRequestBind: sessionStorage.getItem("tokenRequestBind"),
        });
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        sessionStorage.removeItem("idRequestBind");
        sessionStorage.removeItem("tokenRequestBind");
        setShowMensagemSucesso(true);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao solicitar. Tente novamente em instantes.",
          })
        );
      }
    },
  });

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid container style={{ marginBottom: "1rem" }}>
          <Grid item xs={12} align="center">
            <Logo></Logo>
          </Grid>
        </Grid>
        {!showMensagemSucesso && (
          <Typography align="center">
            Informe seus dados para recuperar sua senha:
          </Typography>
        )}
        {showMensagemSucesso && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Alert severity="success" style={{ width: "100%" }}>
                <AlertTitle>Sucesso</AlertTitle>
                <Typography>
                  Foi enviado um link para seu e-mail com as instruções para
                  recuperar sua senha.
                </Typography>
                <Typography>
                  Caso não tenha recebido verifique sua caixa de SPAM.
                </Typography>
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <>
                  <Button
                    variant="contained"
                    type="button"
                    fullWidth
                    onClick={() => {
                      navigation("/app/login");
                    }}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    IR PARA TELA DE LOGIN
                  </Button>
                </>
              </Box>
            </Grid>
          </Grid>
        )}
        {!showMensagemSucesso && (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  id="cpfCnpj"
                  name="cpfCnpj"
                  label="CPF ou CNPJ"
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  value={formik.values.cpfCnpj}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cpfCnpj && Boolean(formik.errors.cpfCnpj)
                  }
                  helperText={formik.touched.cpfCnpj && formik.errors.cpfCnpj}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{ mt: 3, ml: 1 }}
                  >
                    RECUPERAR SENHA
                  </Button>
                </>
              </Box>
            </Grid>
          </form>
        )}
      </Paper>
      <ContatoWhatsApp></ContatoWhatsApp>
    </Container>
  );
}
