import * as React from "react";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as Yup from "yup";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import { useEffect, useState, useCallback, useMemo } from "react";
import ShareIcon from "@mui/icons-material/Share";
import Alert from "@mui/material/Alert";
import { isMobile } from "react-device-detect";
import ClearIcon from "@mui/icons-material/Clear";
import { Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import DialogTipoItemPagamento from "./DialogTipoItemPagamento";
import Button from "@mui/material/Button";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { post } from "@/services/api";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
import { apenasNumeros } from "@/services/utils";
export default function EtapaItemsPagamento({
  permiteVoltarEtapa,
  salvarSimulacaoParaLink,
  setNavegarEtapa,
  dadosUsuario,
  abrirLinkPagamento,
  ehParceiro,
  avancarParaAutenticacao,
}) {
  const dispatch = useDispatch();
  const [arquivos, setArquivos] = React.useState([]);
  const [carregar, setCarregar] = useState(false);
  const [infoDialogTipoItemPagamento, setInfoDialogTipoItemPagamento] =
    useState({ open: false });

  const Schema = Yup.object().shape({
    expireDate: Yup.string(),
  });
  const validaItemsPagamento = (values) => {
    for (let i = 0; i < values.pixs.length; i++) {
      let pix = values.pixs[i];
      if (pix.barcode) {
        if (pix.pixType === "CpfCnpj") {
          const pixKey = apenasNumeros(pix.barcode);
          if (pixKey.length !== 11 && pixKey.length !== 14) {
            return `A chave PIX "${pix.barcode}" não é válida!`;
          }
        }
        if (pix.pixType === "Telefone") {
          const pixKey = apenasNumeros(pix.barcode);
          if (pixKey.length < 8 || pixKey.length > 12) {
            return `A chave PIX "${pix.barcode}" não é válida!`;
          }
        }
        if (pix.pixType === "Email") {
          const pixKey = pix.barcode;
          if (pixKey.indexOf("@") === -1) {
            return `A chave PIX "${pix.barcode}" não é válida!`;
          }
        }
      }
    }
    for (let i = 0; i < values.boletos.length; i++) {
      let boleto = values.boletos[i];
      if (boleto.barcode) {
        const barcode = apenasNumeros(boleto.barcode);
        if (barcode.length < 30) {
          return `O boleto com código "${boleto.barcode}" não é válido!`;
        }
      }
    }
    return null;
  };

  const salvar = (values) => {
    var promise = new Promise(async (resolve, reject) => {
      try {
        const boletos = values.boletos.concat(values.pixs);

        dispatch(activeSpinner());
        const cadastro = {
          boletos: boletos,
          arquivos: arquivos,
          token: dadosUsuario.token,
          idRequest: dadosUsuario.idRequest,
        };
        const result = await post("public/requests-bills", cadastro);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result.error);
          return;
        }
        resolve();
      } catch (e) {
        console.log(e);
        dispatch(
          setInfoDialog({ open: true, descricao: "Ocorreu um erro ao salvar" })
        );
        dispatch(cancelSpinner());
        reject(e);
      }
    });
    return promise;
  };

  const itemPadraoPix = useMemo(() => {
    return {
      id: 1,
      barcode: "",
      pixType: "CpfCnpj",
      beneficiary: "",
      billType: "PIX",
    };
  }, []);

  const itemPadraoBoleto = useMemo(() => {
    return {
      id: 1,
      barcode: "",
      pixType: "CpfCnpj",
      beneficiary: "",
      billType: "boleto",
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      pixs: [],
      boletos: [],
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      if (!boletoOuPixForamInformados()) {
        dispatch(
          setInfoDialog({
            open: true,
            descricao: "Informe os dados de pelo menos um boleto ou PIX!",
          })
        );
        return;
      }
      const mensagemErro = validaItemsPagamento(values);
      if (mensagemErro) {
        dispatch(setInfoDialog({ open: true, descricao: mensagemErro }));
        return;
      }
      await salvar(values);
      avancarParaAutenticacao();
      /* if (dadosUsuario.zipCode) {
        navigation(
          `/app/pagamento/${dadosUsuario.idRequest}/${dadosUsuario.token}`
        );
        return;
      }
      setNavegarEtapa(4);*/
    },
  });

  useEffect(() => {
    setCarregar(true);
  }, []);

  const adicionarBoleto = useCallback(() => {
    const boletos = formik.values.boletos;
    const itemAdd = itemPadraoBoleto;
    itemAdd.id = boletos.length + 1;
    boletos.push(itemAdd);
    formik.setFieldValue("boletos", boletos);
  }, [formik, itemPadraoBoleto]);

  const adicionarPix = useCallback(() => {
    const pixs = formik.values.pixs;
    const itemAdd = itemPadraoPix;
    itemAdd.id = pixs.length + 1;
    pixs.push(itemAdd);
    formik.setFieldValue("pixs", pixs);
  }, [formik, itemPadraoPix]);

  const adicionarBoletoOuPix = useCallback(() => {
    setInfoDialogTipoItemPagamento({
      open: true,
      selecionar: (tipo) => {
        if (tipo === "boleto") {
          adicionarBoleto();
          window.location.href = "#inicio-items-pagamento";
        } else {
          adicionarPix();
        }
      },
    });
  }, [adicionarPix, adicionarBoleto]);

  useEffect(() => {
    if (!carregar) {
      return;
    }
    setCarregar(false);
    adicionarBoletoOuPix();
  }, [
    carregar,
    formik,
    adicionarBoletoOuPix,
    itemPadraoPix,
    itemPadraoBoleto,
    dispatch,
  ]);

  const itemBoletoOuPixFoiAdicionado = () => {
    const boletos = formik.values.boletos.concat(formik.values.pixs);
    return arquivos.length > 0 || boletos.length > 0;
  };

  const boletoOuPixForamInformados = () => {
    const boletos = formik.values.boletos.concat(formik.values.pixs);
    if (arquivos.length > 0) {
      return true;
    }
    for (let i = 0; i < boletos.length; i++) {
      let boleto = boletos[i];
      if (boleto.barcode) {
        return true;
      }
    }
    return false;
  };

  const fileChanged = async (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];
      const myReader = new FileReader();
      if (file.size > 15000000) {
        alert("O arquivo não pode ser maior que 10MB.");
        return;
      }
      var promise = new Promise((resolve, reject) => {
        myReader.onloadend = (e) => {
          let novoArquivo = {};
          novoArquivo.id = file.name + new Date().getTime();
          novoArquivo.nome = file.name;
          novoArquivo.extensao = file.name.split(".").pop();
          novoArquivo.arquivo = myReader.result
            .toString()
            .split(";base64,")
            .pop();
          resolve(novoArquivo);
        };
        myReader.readAsDataURL(file);
      });
      const novoArquivo = await promise;
      const arquivosAtualizados = JSON.parse(JSON.stringify(arquivos));
      arquivosAtualizados.push(novoArquivo);
      setArquivos(arquivosAtualizados);
    }
  };

  const criarLink = async () => {
    const dadosSalvos = await salvarSimulacaoParaLink(dadosUsuario);
    if (boletoOuPixForamInformados()) {
      await salvar(formik.values);
    }
    abrirLinkPagamento(dadosSalvos);
  };
  return (
    <React.Fragment>
      <Container maxWidth="md" id="inicio-items-pagamento">
        <form onSubmit={formik.handleSubmit}>
          <>
            {!dadosUsuario?.infoPartner?.isRequestCompany && (
              <Alert severity="info" sx={{ mb: 1 }}>
                <Typography>
                  Nosso prazo médio é de apenas <b>algumas horas</b>, porém pode
                  levar até <b>3 dias úteis</b> para o débito ser quitado!
                </Typography>
              </Alert>
            )}

            <Grid
              container
              spacing={2}
              justifyContent="center"
              style={{
                paddingBottom: "1rem",
              }}
            >
              {formik.values.boletos.length > 0 && (
                <FormikProvider value={formik}>
                  <FieldArray
                    name="boletos"
                    render={() => (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Paper variant="outlined" sx={{ p: 1, pb: 2 }}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} align="center">
                                <img
                                  src="/icon-boleto2.png"
                                  alt=""
                                  style={{ height: "2.5rem" }}
                                ></img>
                              </Grid>
                              {formik.values.boletos.map((boleto, index) => (
                                <Grid item xs={12} key={`codigoBarras${index}`}>
                                  <TextField
                                    label={"Código de barras do boleto"}
                                    fullWidth
                                    variant="standard"
                                    autoComplete="off"
                                    value={boleto.barcode}
                                    onChange={(e, value) => {
                                      formik.setFieldValue(
                                        `boletos[${index}].barcode`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                              {arquivos.map((arquivo, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  key={"grid" + index}
                                  style={{ marginTop: "0.4rem" }}
                                >
                                  <Typography key={"typography" + index}>
                                    {arquivo.nome}
                                    <ClearIcon
                                      key={"icon" + index}
                                      onClick={() => {
                                        setArquivos(
                                          arquivos.filter(
                                            (item) => item.id !== arquivo.id
                                          )
                                        );
                                      }}
                                      style={{
                                        color: "red",
                                        verticalAlign: "bottom",

                                        cursor: "pointer",
                                      }}
                                    ></ClearIcon>
                                  </Typography>
                                </Grid>
                              ))}
                              <Grid item xs={12}>
                                <Button
                                  variant="text"
                                  component="label"
                                  startIcon={<CloudUploadIcon />}
                                  fullWidth
                                >
                                  Enviar arquivo do boleto
                                  <input
                                    type="file"
                                    onChange={(e) => {
                                      fileChanged(e);
                                    }}
                                    hidden
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </>
                    )}
                  ></FieldArray>
                </FormikProvider>
              )}
              {formik.values.pixs.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <Paper variant="outlined" sx={{ p: 1, pb: 2 }}>
                    <FormikProvider value={formik}>
                      <FieldArray
                        name="pixs"
                        render={() => (
                          <>
                            <Grid container spacing={1}>
                              <Grid item xs={12} align="center">
                                <img
                                  src="/icon-pix2.png"
                                  alt=""
                                  style={{ height: "2.5rem" }}
                                ></img>
                              </Grid>
                            </Grid>
                            {formik.values.pixs.map((boleto, index) => (
                              <Grid container spacing={1} key={`pix${index}`}>
                                <Grid item xs={12}>
                                  <InputLabel shrink id="label-select-province">
                                    Tipo de chave PIX
                                  </InputLabel>
                                  <Select
                                    labelId="label-select-province"
                                    id="pixType"
                                    name="pixType"
                                    label="Tipo de chave PIX *"
                                    fullWidth
                                    variant="standard"
                                    placeholder="Selecione.."
                                    value={boleto.pixType}
                                    onChange={(e, value) => {
                                      formik.setFieldValue(
                                        `pixs[${index}].pixType`,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <MenuItem value="CpfCnpj">
                                      CPF ou CNPJ
                                    </MenuItem>
                                    <MenuItem value="Email">E-mail</MenuItem>
                                    <MenuItem value="Telefone">
                                      Telefone
                                    </MenuItem>
                                    <MenuItem value="Chave aleatória">
                                      Chave aleatória/Copia e cola
                                    </MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    label="Chave PIX"
                                    fullWidth
                                    variant="standard"
                                    value={boleto.barcode}
                                    onChange={(e, value) => {
                                      formik.setFieldValue(
                                        `pixs[${index}].barcode`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    label="Beneficiário"
                                    fullWidth
                                    variant="standard"
                                    value={boleto.beneficiary}
                                    onChange={(e, value) => {
                                      formik.setFieldValue(
                                        `pixs[${index}].beneficiary`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    type="number"
                                    name="value"
                                    label={"Valor do PIX"}
                                    fullWidth
                                    onWheel={() =>
                                      document.activeElement.blur()
                                    }
                                    variant="standard"
                                    placeholder="R$ 0,00"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={boleto.value}
                                    onChange={(e, value) => {
                                      formik.setFieldValue(
                                        `pixs[${index}].value`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sx={{ m: 1 }}>
                                  <Divider />
                                </Grid>
                              </Grid>
                            ))}
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="span"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  * O PIX deverá ser para terceiros e não para o
                                  títular do cartão.
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      ></FieldArray>
                    </FormikProvider>
                  </Paper>
                </Grid>
              )}

              <Grid container sx={{ mt: 3, ml: 1 }}>
                <Grid item xs={12} align="center">
                  <Button
                    variant={
                      itemBoletoOuPixFoiAdicionado() ? "outlined" : "contained"
                    }
                    startIcon={<AddIcon />}
                    onClick={() => {
                      adicionarBoletoOuPix();
                    }}
                  >
                    {itemBoletoOuPixFoiAdicionado()
                      ? "ADICIONAR OUTRO BOLETO OU PIX"
                      : "ADICIONAR BOLETO OU PIX"}
                  </Button>
                </Grid>
              </Grid>

              <Grid container spacing={3} sx={{ mt: 1, ml: 1 }}>
                <Grid item xs={6} sm={6}>
                  {permiteVoltarEtapa() && (
                    <Box>
                      <Button
                        onClick={() => {
                          setNavegarEtapa(2);
                        }}
                      >
                        VOLTAR
                      </Button>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      id="btn-avancar-items-pagamento"
                      type="submit"
                    >
                      AVANÇAR
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        </form>
      </Container>
      {infoDialogTipoItemPagamento?.open && (
        <DialogTipoItemPagamento
          {...{ infoDialogTipoItemPagamento, setInfoDialogTipoItemPagamento }}
        />
      )}
    </React.Fragment>
  );
}
