import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { get } from "@/services/api";
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Moment from "moment";
import LinkCashback from "@/components/LinkCashback";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
import { formatMonetario } from "@/services/utils";
export default function Cashback() {
  const dispatch = useDispatch();
  const [carregar, setCarregar] = useState(false);
  const [cashback, setCashback] = useState({ items: [] });

  useEffect(() => {
    const carregarDados = async () => {
      try {
        dispatch(activeSpinner());
        let result = await get(`secure/cashback`);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(
            setInfoDialog({
              open: true,
              descricao: result.error,
            })
          );
          return;
        }
        setCashback(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao carregar! Tente novamente em instantes.",
          })
        );
      }
    };
    if (carregar) {
      setCarregar(false);
      carregarDados();
    }
  }, [carregar, dispatch]);

  useEffect(() => {
    setCarregar(true);
  }, []);
  const getDescricao = (item) => {
    let texto = "";
    if (item.observacoes) {
      texto += item.observacoes;
      return texto;
    }
    if (!item.endDatetime) {
      if (item.tipo === "indicacao") {
        texto += `Cashback referente a indicação pendente de processamento.`;
        return texto;
      }
      if (item.idRequest) {
        texto += `Cashback pendente de processamento.`;
        return texto;
      }
      texto += "Item pendente de processamento";
      return texto;
    }
    if (item.credito) {
      texto += "Crédito de ";
      texto += formatMonetario(item.credito);
      texto += " referente a ";
      if (item.tipo === "indicacao") {
        texto += " indicação.";
      } else {
        texto += " operação " + item.idRequest + ".";
      }
      return texto;
    }
    texto += "Utilização de Cashback de ";
    texto += formatMonetario(item.debito);
    texto += " referente a operação " + item.idRequest + ".";
    return texto;
  };
  return (
    <Container>
      <Typography variant="h5" style={{ marginBottom: "1rem" }}>
        MEU CASHBACK:
      </Typography>{" "}
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 3 } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Meu saldo atual:{" "}
              <b>
                <span style={{ color: "green" }}>
                  {formatMonetario(cashback.saldo || 0)}
                </span>
              </b>
            </Typography>
          </Grid>

          {cashback.items.length === 0 && (
            <Grid item xs={12}>
              <Typography>
                Você ainda não possui operações que geraram Cashback!
              </Typography>
            </Grid>
          )}

          {cashback?.items?.map((item) => (
            <Grid item xs={12} key={item.id_item_cashback}>
              <Typography>
                <b>{Moment(item.insertDatetime).format("DD/MM/YYYY")}</b> -{" "}
                {getDescricao(item)}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Alert severity="info" style={{ marginTop: "2rem" }}>
          <LinkCashback
            {...{ indicationLink: cashback.indicationLink, telaCashback: true }}
          ></LinkCashback>
        </Alert>
      </Paper>
    </Container>
  );
}
