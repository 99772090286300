import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import "./BotoesInicioSimulacao.scss";

export default function BotoesInicioSimulacao({ iniciaSimulacao }) {
  return (
    <Container component="main" maxWidth="sm">
      <Grid container spacing={1} align="center">
        <Grid item xs={12}>
          <div
            className="botao-escolha-tipo-pagamento"
            onClick={() => {
              iniciaSimulacao("boleto");
            }}
          >
            <img src="/icon-boleto.png" alt=""></img>
            <div className="texto">
              <div className="titulo">BOLETOS</div>
              <div className="descricao">Água, luz, contas diversas, etc</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            className="botao-escolha-tipo-pagamento"
            onClick={() => {
              iniciaSimulacao("PIX");
            }}
          >
            <img src="/icon-pix.png" alt=""></img>
            <div className="texto">
              <div className="titulo">CHAVE PIX</div>
              <div className="descricao">O PIX deve ser para terceiros</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
