import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);

  return (
    <DrawerHeaderStyled
      style={{ backgroundColor: "#2e312e" }}
      theme={theme}
      open={open}
    >
      <Typography
        style={{ color: "#f0f0f0", whiteSpace: "normal", fontSize: "0.75rem" }}
      >{`${user.companyBase.idCompany} - ${user.companyBase.companyName}`}</Typography>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
