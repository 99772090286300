export default function Logo({ maxWidth }) {
  return (
    <div>
      <img
        src="/logo.png"
        style={{ maxWidth: maxWidth || "110px" }}
        alt=""
      ></img>
    </div>
  );
}
