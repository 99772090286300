import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  etapa: "",
  titulo: "",
  mensagem: "",
  selfieNormal: "",
  selfieSorrindo: "",
  fotoFrenteDocumento: "",
  fotoAtrasDocumento: "",
  dadosAutenticacao: undefined,
};

const biometria = createSlice({
  name: "biometria",
  initialState,
  reducers: {
    setEtapa(state, action) {
      state.etapa = action.payload;
    },
    setTitulo(state, action) {
      state.titulo = action.payload;
    },
    setMensagem(state, action) {
      state.mensagem = action.payload;
    },
    setDadosAutenticacao(state, action) {
      state.dadosAutenticacao = action.payload;
    },

    setSelfieNormal(state, action) {
      state.selfieNormal = action.payload;
    },
    setSelfieSorrindo(state, action) {
      state.selfieSorrindo = action.payload;
    },
    setFotoFrenteDocumento(state, action) {
      state.fotoFrenteDocumento = action.payload;
    },
    setFotoAtrasDocumento(state, action) {
      state.fotoAtrasDocumento = action.payload;
    },
  },
});

export default biometria.reducer;

export const {
  setEtapa,
  setTitulo,
  setMensagem,
  setDadosAutenticacao,
  setSelfieNormal,
  setSelfieSorrindo,
  setFotoFrenteDocumento,
  setFotoAtrasDocumento,
} = biometria.actions;
