import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

export default function AvisoAntiFraude({
  antiFraude,
  ativarAntiFraude,
  style,
}) {
  return (
    <>
      {!antiFraude && (
        <Grid item xs={12} style={style}>
          <Alert severity="error">
            <Typography>
              Atenção, o anti-fraude está desativado para esta simulação!
            </Typography>
            {!!ativarAntiFraude && (
              <Typography
                style={{ cursor: "pointer" }}
                onClick={ativarAntiFraude}
              >
                <b>Clique aqui para ativar</b>.
              </Typography>
            )}
          </Alert>
        </Grid>
      )}
    </>
  );
}
