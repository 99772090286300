import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";

export default function DialogConfirmacao({
  infoDialogConfirmacao,
  setInfoDialogConfirmacao,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setInfoDialogConfirmacao({ open: false }));
  };

  const handleNao = () => {
    if (infoDialogConfirmacao.acaoNao) {
      infoDialogConfirmacao.acaoNao();
    }
    handleClose();
  };
  const handleSim = () => {
    if (infoDialogConfirmacao.acaoSim) {
      infoDialogConfirmacao.acaoSim();
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={infoDialogConfirmacao.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {infoDialogConfirmacao.titulo || "Aviso"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {infoDialogConfirmacao.descricao}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNao}>Não</Button>
          <Button onClick={handleSim} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
