import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { get } from "@/services/api";
import AlertTitle from "@mui/material/AlertTitle";
import { useFormik } from "formik";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Logo from "@/components/Logo";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { formatMonetario } from "@/services/utils";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";

export default function Finalizacao() {
  const navigation = useNavigate();
  const { idRequest, token, areaSistema } = useParams();
  const dispatch = useDispatch();
  const [carregar, setCarregar] = React.useState(false);
  const [dadosUsuario, setDadosUsuario] = React.useState({});

  const Schema = Yup.object().shape({
    expireDate: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {},
    validationSchema: Schema,
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    const carregarDados = async () => {
      try {
        dispatch(activeSpinner());
        const result = await get(`public/request-info/${idRequest}/${token}`);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        setDadosUsuario(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao consultar seus dados. Por favor atualize a página!",
          })
        );
      }
    };
    if (carregar && token && idRequest) {
      setCarregar(false);
      carregarDados();
      return;
    }
  }, [carregar, dispatch, token, idRequest]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  const voltarAoInicio = () => {
    if (areaSistema === "parceiro") {
      navigation("/dashboard");
    } else {
      navigation("/app/area-cliente");
    }
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      style={{ maxWidth: "600px" }}
      sx={{ mb: 4 }}
    >
      <Paper
        variant="outlined"
        sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} align="center">
            <Logo></Logo>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="success" style={{ width: "100%" }}>
              <AlertTitle>Sucesso</AlertTitle>
              <Typography>Pagamento realizado com sucesso. </Typography>
              <Typography>
                Seu pedido passará por uma análise de segurança.
              </Typography>
              {areaSistema === "parceiro" && (
                <Typography>
                  Em breve o cliente receberá por e-mail os detalhes da
                  contratação.
                </Typography>
              )}
              {areaSistema !== "parceiro" && (
                <Typography>
                  Em breve você receberá por e-mail os detalhes de sua
                  contratação.
                </Typography>
              )}
              <Typography>
                ID do pedido: <b>{dadosUsuario.idRequestView}</b>
              </Typography>
              <Typography>
                Total transacionado:{" "}
                <b>{formatMonetario(dadosUsuario.total)}</b>
              </Typography>
            </Alert>
          </Grid>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Button
                variant="text"
                onClick={() => {
                  voltarAoInicio();
                }}
              >
                VOLTAR AO INÍCIO
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
