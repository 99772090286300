import Container from "@mui/material/Container";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { apenasLetrasNumeros } from "@/services/utils";
import Typography from "@mui/material/Typography";
import { post } from "@/services/api";
import Alert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { get } from "@/services/api";
import * as React from "react";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
export default function EtapaInicioComLogin({
  cupomUtilizar,
  salvarSimulacao,
  setNavegarEtapa,
  setDadosUsuario,
}) {
  const dispatch = useDispatch();

  const [carregar, setCarregar] = React.useState(false);

  useEffect(() => {
    setCarregar(true);
  }, []);

  const Schema = Yup.object().shape({
    billValue: Yup.number().required("Informe o valor da simulação"),
  });

  const formik = useFormik({
    initialValues: { billValue: "", cupom: "" },
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        if (values.cupom) {
          const cupomValidate = apenasLetrasNumeros(values.cupom);
          dispatch(activeSpinner());
          const result = await post("secure/cupom/validate", {
            cupom: cupomValidate,
          });
          dispatch(cancelSpinner());
          if (result.error) {
            dispatch(
              setInfoDialog({
                open: true,
                descricao: result.error,
              })
            );
            formik.setFieldValue("cupom", "");
            return;
          }
          values.cupomUtilizar = result.data.codigo;
        }
        const result = await salvarSimulacao(values);
        setDadosUsuario(result);
        setNavegarEtapa(2);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao: "Ocorreu um erro ao validar",
          })
        );
      }
    },
  });

  useEffect(() => {
    const carregarDados = async () => {
      try {
        sessionStorage.removeItem("dadosUsuarioSimulacao");
        dispatch(activeSpinner());
        let result = await get(`secure/user`);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(
            setInfoDialog({
              open: true,
              descricao: result.error,
            })
          );
          return;
        }
        setDadosUsuario(result.data);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao carregar! Tente novamente em instantes.",
          })
        );
      }
    };
    if (carregar) {
      if (cupomUtilizar) {
        formik.setFieldValue("cupom", cupomUtilizar);
      }
      setCarregar(false);
      carregarDados();
    }
  }, [carregar, formik, cupomUtilizar, setDadosUsuario, dispatch]);

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 1 }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={12} sm={10}>
            <Alert severity="info" style={{ marginBottom: "1rem" }}>
              <Typography>
                Se você possui um <b>cupom de desconto</b> informe o código
                abaixo:
              </Typography>
              <TextField
                name="cupom"
                label="Cupom de desconto"
                variant="standard"
                value={formik.values.cupom}
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={formik.handleChange}
              />
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6" sx={{ mb: 1 }}>
              Simule e solicite o parcelamento agora mesmo:
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={5}>
            <TextField
              type="number"
              name="billValue"
              label={"Valor total da simulação *"}
              fullWidth
              onWheel={() => document.activeElement.blur()}
              variant="standard"
              placeholder="R$ 0,00"
              value={formik.values.billValue}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={
                formik.touched.billValue && Boolean(formik.errors.billValue)
              }
              helperText={formik.touched.billValue && formik.errors.billValue}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              id="btn-avancar-dados-pessoais"
              type="submit"
              fullWidth
            >
              SIMULAR
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
