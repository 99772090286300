import * as React from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import { Helmet } from "react-helmet";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import ContatoWhatsApp from "@/components/ContatoWhatsApp";
import Logo from "@/components/Logo";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { post } from "@/services/api";

import { useDispatch } from "react-redux";
import { activeSpinner, cancelSpinner } from "@/store/reducers/geral";
export default function Login() {
  const dispatch = useDispatch();
  const [avisoLogin, setAvisoLogin] = React.useState(false);
  const [escolherFormaLogin, setEscolherFormaLogin] = React.useState(false);
  const navigation = useNavigate();

  const Schema = Yup.object().shape({
    cpfCnpj: Yup.string().required("Informe seu CPF ou CNPJ"),
    password: Yup.string().required("Informe sua senha"),
  });
  const formik = useFormik({
    initialValues: {
      cpfCnpj: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        dispatch(activeSpinner());
        setAvisoLogin({});

        const result = await post("public/login", values);
        dispatch(cancelSpinner());
        if (result.error) {
          setAvisoLogin({ open: true, descricao: result.error });
          return;
        }
        if (result.data.selectTypeUser) {
          formik.setFieldValue("loginType", "PARCEIRO");
          setEscolherFormaLogin(true);
          return;
        } else if (result.data.tokenPartner) {
          window.location.href = result.data.tokenPartner;
          return;
        }
        sessionStorage.setItem(
          "dadosUsuarioLogado",
          JSON.stringify(result.data.user)
        );
        navigation("/app/area-cliente/inicio");
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        setAvisoLogin({
          open: true,
          descricao:
            "Estamos atualizando nosso sistema. Por favor tente novamente em instantes.",
        });
      }
    },
  });

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      {" "}
      <Helmet>
        <title>Login</title>
        <link rel="canonical" href="https://quitaboletos.com.br/app/login" />
        <meta
          name="description"
          content="Acesse a área do cliente para ver suas operações e contratar o parcelamento"
        />
      </Helmet>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 2 } }}
      >
        <Grid container>
          <Grid item xs={12} align="center">
            <Logo></Logo>
          </Grid>
        </Grid>
        <Grid container sx={{ p: { xs: 1 } }}>
          {avisoLogin.open && (
            <Grid item xs={12}>
              <Alert
                severity={avisoLogin.type || "error"}
                onClose={() => {
                  setAvisoLogin({ open: false });
                }}
              >
                {avisoLogin.descricao}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} align="center">
            <Typography align="center">Olá</Typography>
          </Grid>
        </Grid>
        <Typography align="center">
          Digite o seu CPF ou CNPJ abaixo para acessar.
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="cpfCnpj"
                name="cpfCnpj"
                label="CPF ou CNPJ"
                fullWidth
                autoComplete="username"
                variant="standard"
                disabled={escolherFormaLogin}
                value={formik.values.cpfCnpj}
                onChange={formik.handleChange}
                error={formik.touched.cpfCnpj && Boolean(formik.errors.cpfCnpj)}
                helperText={formik.touched.cpfCnpj && formik.errors.cpfCnpj}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Senha"
                type="password"
                autoComplete="current-password"
                fullWidth
                disabled={escolherFormaLogin}
                variant="standard"
                value={formik.values.password}
                onChange={(event) => {
                  formik.setFieldValue("password", event.target.value);
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            {escolherFormaLogin && (
              <>
                <Grid item xs={12}>
                  <Alert severity={"warning"}>
                    Escolha como deseja entrar:
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel shrink id="label-select-entrar">
                    Entrar como
                  </InputLabel>
                  <Select
                    labelId="label-select-entrar"
                    id="loginType"
                    name="loginType"
                    label="Entrar como"
                    fullWidth
                    variant="standard"
                    value={formik.values.loginType}
                    onChange={(event) => {
                      formik.setFieldValue("loginType", event.target.value);
                    }}
                  >
                    <MenuItem value="PARCEIRO">PARCEIRO</MenuItem>
                    <MenuItem value="CLIENTE">CLIENTE</MenuItem>
                  </Select>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                sx={{ mt: 3, ml: 1 }}
              >
                {escolherFormaLogin ? "CONTINUAR" : "ENTRAR"}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <Box>
              <Button
                variant="text"
                fullWidth
                onClick={() => {
                  navigation("/app/esqueci-minha-senha");
                }}
              >
                ESQUECI MINHA SENHA
              </Button>
            </Box>
          </Grid>
        </form>
        <Typography align="center" variant="h6" sx={{ mt: 3 }}>
          Ainda não possuo conta!
        </Typography>

        <Grid item xs={12}>
          <Box>
            <Button
              variant="outlined"
              type="button"
              fullWidth
              sx={{ mt: 1, ml: 1 }}
              onClick={() => {
                navigation("/app/cadastro");
              }}
            >
              CRIAR CONTA
            </Button>
          </Box>
        </Grid>
      </Paper>
    </Container>
  );
}
