import MinimalLayout from "@/layout/MinimalLayout";
import SimulacaoClienteSemLogin from "@/pages/cliente/areaAberta/simulacao/SimulacaoClienteSemLogin";

const GeralRoutes = {
  path: "",
  element: <MinimalLayout />,
  children: [
    {
      path: "",
      element: <SimulacaoClienteSemLogin />,
    },

    {
      path: "*",
      element: <SimulacaoClienteSemLogin />,
    },
  ],
};

export default GeralRoutes;
