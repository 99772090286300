import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { ehHomologacao } from "../../services/api";

const ResponsiveAppBar = (params: any) => {
  const location = useLocation();
  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const ehTelaLogin = () => {
    return location.pathname.indexOf("login") !== -1;
  };

  return (
    <AppBar className="header" position="static">
      <Container className="header" maxWidth="xl">
        <Toolbar className="header" disableGutters>
          <Typography
            variant="h6"
            noWrap
            style={{ color: "white" }}
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              textDecoration: "none",
            }}
          >
            <img src="/logo_branco.png" className="logo-header" alt=""></img>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{ color: "white" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  navigation("/app/login");
                }}
              >
                <Typography textAlign="center">LOGIN</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src="/logo_branco.png" className="logo-header" alt=""></img>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            {!ehTelaLogin() && (
              <>
                <Button
                  className="header-item header-item-entrar"
                  onClick={() => {
                    navigation("/app/login");
                  }}
                  sx={{ my: 2, ml: 1, color: "white", display: "block" }}
                >
                  Login
                </Button>
              </>
            )}
            {ehTelaLogin() && (
              <Button
                className="header-item header-item-entrar"
                onClick={() => {
                  navigation("/parceiro/login");
                }}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Área do parceiro
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
