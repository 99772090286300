import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { post } from "@/services/api";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { initialValuesForm } from "../../../common/camposUsuario";
import * as Yup from "yup";
import { setInfoToaster } from "@/store/reducers/geral";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
export default function AlterarSenha() {
  const dispatch = useDispatch();

  const Schema = Yup.object().shape({
    password: Yup.string().required("Informe sua senha atual"),
    newPassword: Yup.string()
      .required("Informe a nova senha")
      .test("len", "A senha deve conter pelo menos 6 digítos", (val) => {
        return (val || "").toString().length >= 6;
      }),
    confirmPassword: Yup.string()
      .required("Informe a confirmação")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Senha e confirmação estão diferentes"
      ),
  });

  const formik = useFormik({
    initialValues: initialValuesForm,
    validationSchema: Schema,
    onSubmit: async (values) => {
      dispatch(activeSpinner());
      try {
        const result = await post("secure/user/password", values);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        formik.resetForm();
        dispatch(
          setInfoToaster({
            open: true,
            message: "Senha alterada com sucesso!",
            type: "success",
          })
        );
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao salvar. Tente novamente em instantes!",
          })
        );
      }
    },
  });

  return (
    <Container>
      <Typography variant="h5" style={{ marginBottom: "1rem" }}>
        ALTERAR SENHA:
      </Typography>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 3 } }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Senha atual"
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
                fullWidth
                variant="standard"
                value={formik.values.password}
                onChange={(event) => {
                  formik.setFieldValue("password", event.target.value);
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Senha"
                type="password"
                id="newPassword"
                name="newPassword"
                autoComplete="current-password"
                fullWidth
                variant="standard"
                value={formik.values.newPassword}
                onChange={(event) => {
                  formik.setFieldValue("newPassword", event.target.value);
                }}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Confirme a senha"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                autoComplete="current-password"
                fullWidth
                variant="standard"
                value={formik.values.confirmPassword}
                onChange={(event) => {
                  formik.setFieldValue("confirmPassword", event.target.value);
                }}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} align="right">
              <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
                ALTERAR SENHA
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
