import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import "./styleInicio.scss";

export default function Inicio() {
  const navigation = useNavigate();

  const iniciaSimulacao = () => {
    navigation("/app/simulacao/etapa/1");
  };

  return (
    <Container component="main">
      <Grid container spacing={1} align="center" sx={{ p: 3 }}>
        <Grid item xs={12}>
          <div
            className="botao-iniciar-simulacao"
            onClick={() => {
              iniciaSimulacao();
            }}
          >
            <div className="texto">
              <div className="titulo">Clique aqui para iniciar a simulação</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
