import Simulacao from "@/pages/common/simulacao/Simulacao";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { get } from "@/services/api";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { initialValuesForm } from "../../../common/camposUsuario";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";

export default function SimulacaoClienteComLogin() {
  const { idRequest, tokenRequest, cupomUtilizar } = useParams();
  const [carregar, setCarregar] = useState(false);
  const [navegarEtapa, setNavegarEtapa] = useState(null);
  const [dadosUsuario, setDadosUsuario] = useState({});
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [carregarEtapa, setCarregarEtapa] = useState(false);

  const navegarSimulacao = useCallback(
    (idRequest, token, etapa) => {
      navigation(
        `/app/area-cliente/simulacao/${idRequest}/${token}/etapa/${etapa}`
      );
    },
    [navigation]
  );

  const iniciaSimulacao = useCallback(
    (dados, idCompany, idUserSystem) => {
      const metaData = JSON.parse(sessionStorage.getItem("metaData") || "{}");
      setDadosUsuario({
        ...initialValuesForm,
        ...dados,
        acessoCliente: true,
        cupom: cupomUtilizar,
        metaData,
        idCompany,
        idUserSystem,
      });
      if (dados.step) {
        navegarSimulacao(dados.idRequest, dados.token, dados.step, metaData);
      } else {
        navigation("/app/area-cliente/simulacao/etapa/1");
      }
    },
    [navigation, cupomUtilizar, navegarSimulacao]
  );

  useEffect(() => {
    if (navegarEtapa) {
      navigation(
        `/app/area-cliente/simulacao/${dadosUsuario.idRequest}/${dadosUsuario.token}/etapa/${navegarEtapa}`
      );
      setNavegarEtapa(null);
    }
  }, [navegarEtapa, dadosUsuario.idRequest, dadosUsuario.token, navigation]);

  useEffect(() => {
    const carregarDadosRequest = async () => {
      try {
        dispatch(activeSpinner());
        const result = await get(
          `public/request-info/${idRequest}/${tokenRequest}`
        );
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        setDadosUsuario(result);
        setCarregarEtapa(true);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao consultar seus dados. Por favor atualize a página!",
          })
        );
      }
    };
    if (!carregar) {
      return;
    }
    setCarregar(false);
    if (idRequest && tokenRequest) {
      carregarDadosRequest();
    } else {
      iniciaSimulacao(dadosUsuario);
    }
  }, [
    carregar,
    dispatch,
    dadosUsuario,
    iniciaSimulacao,
    navigation,
    idRequest,
    tokenRequest,
  ]);

  const limparDados = () => {
    setDadosUsuario({});
    setCarregar(true);
  };
  useEffect(() => {
    setCarregar(true);
  }, []);

  const abrirLinkPagamento = (dados) => {
    const dadosSimulacao = {};
    dadosSimulacao.idRequest = dados.idRequestView;
    dadosSimulacao.paymentLink = dados.requestLink;
    dadosSimulacao.billValue = dados.billValue;
    dadosSimulacao.ehParceiro = true;
    sessionStorage.setItem("dadosSimulacao", JSON.stringify(dadosSimulacao));
    navigation(`/app/link-pagamento`);
  };

  return (
    <Simulacao
      {...{
        navegarSimulacao,
        dadosUsuario,
        setDadosUsuario,
        carregarEtapa,
        setCarregarEtapa,
        setNavegarEtapa,
        limparDados,
        abrirLinkPagamento,
        ehAreaComLogin: true,
        cupomUtilizar,
      }}
    ></Simulacao>
  );
}
