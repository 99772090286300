import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GppGoodIcon from "@mui/icons-material/GppGood";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import MoneyIcon from "@mui/icons-material/Money";
import ApiIcon from "@mui/icons-material/Api";
import ReorderIcon from "@mui/icons-material/Reorder";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
const dashboard = {
  id: "group-dashboard",
  title: "",
  type: "group",
  url: "/dashboard",
  children: [
    {
      id: "dashboard",
      title: "INÍCIO",
      type: "item",
      url: "",
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "operacoes",
      title: "OPERAÇÕES",
      type: "item",
      url: "operacoes",
      icon: ReorderIcon,
      breadcrumbs: false,
    },
    {
      id: "simular",
      title: "SIMULAR",
      type: "item",
      url: `simular`,
      icon: ShoppingCartIcon,
      breadcrumbs: false,
      permissao: "criar-simulacoes",
    },
    {
      id: "clientes",
      title: "CLIENTES",
      type: "item",
      url: "clientes",
      icon: GroupIcon,
      breadcrumbs: false,
      permissao: "visualizar-clientes",
    },
    {
      id: "parceiros",
      title: "EMPRESAS",
      type: "item",
      url: "parceiros",
      icon: BusinessIcon,
      breadcrumbs: false,
      permissao: "visualizar-empresas",
    },
    {
      id: "meus-saldos",
      title: "MEUS SALDOS",
      type: "item",
      url: `meus-saldos`,
      icon: MoneyIcon,
      breadcrumbs: false,
      permissao: "visualizar-saldos",
    },
    {
      id: "comissoes",
      title: "COMISSIONAMENTO",
      type: "item",
      url: "comissoes",
      icon: CurrencyExchangeIcon,
      breadcrumbs: false,
      permissao: "visualizar-comissoes",
    },
    {
      id: "comissoes-2",
      title: "COMISSÕES",
      type: "item",
      url: "comissoes-gerais",
      icon: CurrencyExchangeIcon,
      breadcrumbs: false,
      permissao: "visualizar-comissoes-2",
    },
    {
      id: "controle-acesso",
      title: "CONTROLE DE ACESSO",
      type: "item",
      url: "controle-acesso",
      icon: GppGoodIcon,
      breadcrumbs: false,
      permissao: "controle-acesso",
    },
    /*
    {
      id: "meus-dados",
      title: "MINHA EMPRESA",
      type: "item",
      url: "meus-dados",
      icon: PersonIcon,
      breadcrumbs: false,
      permissao: "alterar-dados-empresa",
    },
    {
      id: "alterar-senha",
      title: "ALTERAR SENHA",
      type: "item",
      url: "alterar-senha",
      icon: VpnKeyIcon,
      breadcrumbs: false,
    },*/
  ],
};

export default dashboard;
