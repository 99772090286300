import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { post } from "@/services/api";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { apenasLetrasNumeros } from "@/services/utils";
import {
  activeSpinner,
  setInfoDialog,
  cancelSpinner,
} from "@/store/reducers/geral";
export default function DialogCupom({ infoDialogCupom, setInfoDialogCupom }) {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [cupom, setCupom] = React.useState(undefined);

  const cancelar = () => {
    setInfoDialogCupom({ open: false });
  };

  const salvar = async () => {
    try {
      if (!cupom) {
        dispatch(
          setInfoDialog({
            open: true,
            descricao: "Informe o código do seu cupom!",
          })
        );
        setCupom("");
        return;
      }
      const cupomValidate = apenasLetrasNumeros(cupom);
      dispatch(activeSpinner());
      const result = await post("secure/cupom/validate", {
        cupom: cupomValidate,
      });
      dispatch(cancelSpinner());
      if (result.error) {
        dispatch(
          setInfoDialog({
            open: true,
            descricao: result.error,
          })
        );
        setCupom("");
        return;
      }
      navigation("/app/area-cliente/simulacao/cupom/" + cupom);
    } catch (e) {
      console.log(e);
      dispatch(cancelSpinner());
      dispatch(
        setInfoDialog({ open: true, descricao: "Ocorreu um erro ao verificar" })
      );
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="xs"
        open={infoDialogCupom.open}
        onClose={cancelar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography align="center">
                Informe abaixo o código do seu cupom:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="cupom"
                label="Cupom de desconto"
                variant="standard"
                value={cupom}
                fullWidth
                autoComplete="off"
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={(event) => {
                  setCupom(event.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                type="button"
                id="btn-avancar-valores"
                fullWidth
                onClick={() => {
                  salvar();
                }}
                sx={{ ml: 1 }}
              >
                CONTINUAR
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelar} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
