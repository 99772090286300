import HomeIcon from "@mui/icons-material/Home";

const dashboard = {
  id: "group-dashboard",
  title: "",
  type: "group",
  children: [
    {
      id: "inicio",
      title: "INÍCIO",
      type: "item",
      url: "/app/area-cliente/inicio",
      icon: HomeIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
