import * as React from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { getExterno } from "@/services/apiExterna";
import Logo from "@/components/Logo";
import ContatoWhatsApp from "@/components/ContatoWhatsApp";
import { useFormik } from "formik";
import { get, post } from "@/services/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { initialValuesForm } from "../../../common/camposUsuario";
import { apenasNumeros } from "@/services/utils";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  setInfoToaster,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";

export default function EtapaDadosPessoais1() {
  const { idRequest, token } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [carregar, setCarregar] = React.useState(false);

  const Schema = Yup.object().shape({
    cnpj: Yup.string().when("personType", {
      is: "PJ",
      then: Yup.string()
        .required("Informe o CNPJ")
        .test("len", "CNPJ inválido", (val) => {
          return (val || "").toString().replace(/\D/g, "").length === 14;
        }),
    }),
    companyName: Yup.string().when("personType", {
      is: "PJ",
      then: Yup.string().required("Informe a razão social"),
    }),
    firstName: Yup.string().when("personType", {
      is: "PF",
      then: Yup.string().required("Informe o primeiro nome"),
    }),
    lastName: Yup.string().when("personType", {
      is: "PF",
      then: Yup.string().required("Informe o sobrenome"),
    }),
    cpf: Yup.string().when("personType", {
      is: "PF",
      then: Yup.string()
        .required("Informe o CPF")
        .test("len", "CPF inválido", (val) => {
          return apenasNumeros(val).length === 11;
        }),
    }),
    whatsapp: Yup.string()
      .test("len", "Celular inválido", (val) => {
        return apenasNumeros(val).length === 11;
      })
      .required("Informe o celular"),
    email: Yup.string().email("E-mail inválido").required("Informe o e-mail"),

    zipCode: Yup.string()
      .nullable()
      .required("Informe o CEP")
      .test("len", "CEP inválido", (val) => {
        return apenasNumeros(val).length === 8;
      }),
    street: Yup.string().nullable().required("Informe a rua"),
    number: Yup.string().nullable().required("Informe a número"),
    neighborhood: Yup.string().nullable().required("Informe o bairro"),
    city: Yup.string().nullable().required("Informe a cidade"),
    province: Yup.string().nullable().required("Informe o estado"),
    newPassword: Yup.string().when("cadastroSocialLogin", {
      is: false,
      then: Yup.string()
        .required("Informe uma senha para acesso")
        .test("len", "A senha deve conter pelo menos 6 digítos", (val) => {
          return (val || "").toString().length >= 6;
        }),
    }),
    confirmPassword: Yup.string().when("cadastroSocialLogin", {
      is: false,
      then: Yup.string()
        .required("Informe a confirmação")
        .oneOf(
          [Yup.ref("newPassword"), null],
          "Senha e confirmação estão diferentes"
        ),
    }),
  });
  const handleChangeCep = async (value) => {
    formik.setFieldValue("zipCode", value);
    const zipCode = (value || "").replace(/\D/g, "");
    if (zipCode.length !== 8 || formik.values.street) {
      return;
    }
    dispatch(activeSpinner());
    try {
      const url = `https://viacep.com.br/ws/${zipCode}/json/`;
      let request = await getExterno(url);
      dispatch(cancelSpinner());
      if (request && request.logradouro) {
        formik.setFieldValue("neighborhood", request.bairro);
        formik.setFieldValue("street", request.logradouro);
        formik.setFieldValue("city", request.localidade);
        formik.setFieldValue("province", request.uf);
      }
    } catch (e) {
      console.log(e);
      dispatch(cancelSpinner());
    }
  };

  const formik = useFormik({
    initialValues: initialValuesForm,
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        values = { ...values };
        const tokenFCM = localStorage.getItem("tokenFCM");
        if (tokenFCM) {
          values.tokenFCM = tokenFCM;
        }
        values.cpf = apenasNumeros(values.cpf);
        values.cnpj = apenasNumeros(values.cnpj);
        values.whatsapp = apenasNumeros(values.whatsapp);
        values.password = values.newPassword;
        values.idRequestBind = sessionStorage.getItem("idRequestBind");
        values.tokenRequestBind = sessionStorage.getItem("tokenRequestBind");

        dispatch(activeSpinner());
        const result = await post("public/register", values);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        sessionStorage.setItem("dadosUsuarioLogado", JSON.stringify(result));
        dispatch(
          setInfoToaster({
            open: true,
            message: "Cadastro efetuado com sucesso.",
            type: "success",
          })
        );
        navigation("/app/area-cliente/inicio");
        /*
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Cadastro efetuado com sucesso, você será redirecionado para a tela de login!",
            acaoOk: () => {
              navigation(`/app/login`);
            },
          })
        );*/
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({ open: true, descricao: "Ocorreu um erro ao salvar" })
        );
      }
    },
  });
  const camposInvalidos = () => {
    return Object.values(formik.errors).length > 0 && formik.submitCount > 0;
  };

  useEffect(() => {
    const carregarDados = async () => {
      try {
        dispatch(activeSpinner());
        const result = await get(`public/request-info/${idRequest}/${token}`);
        dispatch(cancelSpinner());
        if (result.error) {
          return;
        }
        let request = result;
        formik.setValues({
          ...formik.values,
          companyName: request.name,
          firstName: request.firstName,
          lastName: request.lastName,
          cpf: request.cpf,
          cnpj: request.cnpj,
          personType: request.personType,
          email: request.email,
          whatsapp: request.whatsapp,
        });
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
      }
    };
    if (carregar) {
      if (token && idRequest) {
        setCarregar(false);
        carregarDados();
        return;
      } else {
        let dadosCadastro = sessionStorage.getItem("dadosCadastro");
        if (dadosCadastro) {
          sessionStorage.removeItem("dadosCadastro");
          dadosCadastro = JSON.parse(dadosCadastro);
          if (!dadosCadastro.email) {
            return;
          }
          formik.setValues({
            ...formik.values,
            firstName: dadosCadastro.firstName,
            lastName: dadosCadastro.lastName,
            personType: "PF",
            email: dadosCadastro.email,
            cadastroSocialLogin: true,
          });
        }
      }
    }
  }, [carregar, dispatch, formik, token, idRequest]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}
      >
        <Grid container>
          <Grid item xs={12} align="center">
            <Logo></Logo>
          </Grid>
        </Grid>
        <Typography align="center">Bem-vindo(a)!</Typography>
        <Typography align="center" style={{ marginBottom: "1rem" }}>
          Informe seus dados abaixo para se cadastrar:
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormLabel id="demo-radio-buttons-group-label">Sou:</FormLabel>
              <RadioGroup
                defaultValue="PF"
                value={formik.values.personType}
                onChange={formik.handleChange}
                row
                name="personType"
              >
                <FormControlLabel
                  value="PF"
                  control={<Radio />}
                  label="Pessoa física"
                />
                <FormControlLabel
                  value="PJ"
                  control={<Radio />}
                  label="Pessoa jurídica"
                />
              </RadioGroup>
            </Grid>
            {formik.values.personType === "PF" && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="Primeiro nome *"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Sobrenome *"
                    fullWidth
                    autoComplete="family-name"
                    variant="standard"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
              </>
            )}
            {formik.values.personType === "PJ" && (
              <Grid item xs={12} sm={12}>
                <TextField
                  id="companyName"
                  name="companyName"
                  label="Razão social *"
                  fullWidth
                  variant="standard"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                />
              </Grid>
            )}
            {formik.values.personType === "PF" && (
              <Grid item xs={12}>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="999.999.999-99"
                    name="cpf"
                    value={formik.values.cpf}
                    onChange={formik.handleChange}
                  >
                    {() => (
                      <TextField
                        id="cpf"
                        name="cpf"
                        label="CPF *"
                        fullWidth
                        variant="standard"
                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                        helperText={formik.touched.cpf && formik.errors.cpf}
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
            )}
            {formik.values.personType !== "PF" && (
              <Grid item xs={12}>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    value={formik.values.cnpj}
                    onChange={formik.handleChange}
                  >
                    {() => (
                      <TextField
                        id="cnpj"
                        name="cnpj"
                        label="CNPJ *"
                        fullWidth
                        variant="standard"
                        error={
                          formik.touched.cnpj && Boolean(formik.errors.cnpj)
                        }
                        helperText={formik.touched.cnpj && formik.errors.cnpj}
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <InputMask
                mask="(99) 9 9999-9999"
                value={formik.values.whatsapp}
                name="whatsapp"
                onChange={formik.handleChange}
              >
                {() => (
                  <TextField
                    id="whatsapp"
                    name="whatsapp"
                    label="Telefone/WhatsApp *"
                    fullWidth
                    autoComplete="tel"
                    variant="standard"
                    error={
                      formik.touched.whatsapp && Boolean(formik.errors.whatsapp)
                    }
                    helperText={
                      formik.touched.whatsapp && formik.errors.whatsapp
                    }
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                name="email"
                label="E-mail *"
                fullWidth
                disabled={formik.values.cadastroSocialLogin}
                autoComplete="email"
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="99999-999"
                name="zipCode"
                value={formik.values.zipCode}
                onChange={(event) => handleChangeCep(event.target.value)}
              >
                {() => (
                  <TextField
                    id="zipCode"
                    name="zipCode"
                    label={"CEP *"}
                    variant="standard"
                    error={
                      formik.touched.zipCode && Boolean(formik.errors.zipCode)
                    }
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="street"
                name="street"
                label={"Rua *"}
                fullWidth
                variant="standard"
                value={formik.values.street}
                onChange={formik.handleChange}
                error={formik.touched.street && Boolean(formik.errors.street)}
                helperText={formik.touched.street && formik.errors.street}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="number"
                name="number"
                label={"Número *"}
                fullWidth
                variant="standard"
                value={formik.values.number}
                onChange={formik.handleChange}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="complement"
                name="complement"
                label="Complemento"
                fullWidth
                variant="standard"
                value={formik.values.complement}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="neighborhood"
                name="neighborhood"
                label={"Bairro *"}
                fullWidth
                variant="standard"
                value={formik.values.neighborhood}
                onChange={formik.handleChange}
                error={
                  formik.touched.neighborhood &&
                  Boolean(formik.errors.neighborhood)
                }
                helperText={
                  formik.touched.neighborhood && formik.errors.neighborhood
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="city"
                name="city"
                label={"Cidade *"}
                fullWidth
                variant="standard"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel shrink id="label-select-province">
                {"UF *"}
              </InputLabel>
              <Select
                labelId="label-select-province"
                id="province"
                name="province"
                label={"UF *"}
                fullWidth
                variant="standard"
                value={formik.values.province}
                error={
                  formik.touched.province && Boolean(formik.errors.province)
                }
                onChange={(event) => {
                  formik.setFieldValue("province", event.target.value);
                }}
              >
                <MenuItem value="AC">AC</MenuItem>
                <MenuItem value="AL">AL</MenuItem>
                <MenuItem value="AP">AP</MenuItem>
                <MenuItem value="AM">AM</MenuItem>
                <MenuItem value="BA">BA</MenuItem>
                <MenuItem value="CE">CE</MenuItem>
                <MenuItem value="ES">ES</MenuItem>
                <MenuItem value="GO">GO</MenuItem>
                <MenuItem value="MA">MA</MenuItem>
                <MenuItem value="MT">MT</MenuItem>
                <MenuItem value="MS">MS</MenuItem>
                <MenuItem value="MG">MG</MenuItem>
                <MenuItem value="PA">PA</MenuItem>
                <MenuItem value="PB">PB</MenuItem>
                <MenuItem value="PR">PR</MenuItem>
                <MenuItem value="PE">PE</MenuItem>
                <MenuItem value="PI">PI</MenuItem>
                <MenuItem value="RJ">RJ</MenuItem>
                <MenuItem value="RN">RN</MenuItem>
                <MenuItem value="RS">RS</MenuItem>
                <MenuItem value="RO">RO</MenuItem>
                <MenuItem value="RR">RR</MenuItem>
                <MenuItem value="SC">SC</MenuItem>
                <MenuItem value="SP">SP</MenuItem>
                <MenuItem value="SE">SE</MenuItem>
                <MenuItem value="TO">TO</MenuItem>
                <MenuItem value="DF">DF</MenuItem>
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {formik.touched.province && formik.errors.province}
              </FormHelperText>
            </Grid>
            {!formik.values.cadastroSocialLogin && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Senha para acesso *"
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    autoComplete="current-password"
                    fullWidth
                    variant="standard"
                    value={formik.values.newPassword}
                    onChange={(event) => {
                      formik.setFieldValue("newPassword", event.target.value);
                    }}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Confirme a senha *"
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    fullWidth
                    variant="standard"
                    value={formik.values.confirmPassword}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "confirmPassword",
                        event.target.value
                      );
                    }}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container style={{ marginTop: "1rem" }}>
            <Grid item xs={12}>
              <FormControlLabel
                onChange={() => {
                  formik.setFieldValue(
                    "aceita_email",
                    !formik.values.aceita_email
                  );
                }}
                name="aceita_email"
                value={formik.values.aceita_email}
                control={<Checkbox defaultChecked />}
                label="Aceito receber avisos por e-mail"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                onChange={() => {
                  formik.setFieldValue(
                    "aceita_push",
                    !formik.values.aceita_push
                  );
                }}
                name="aceita_push"
                value={formik.values.aceita_push}
                control={<Checkbox defaultChecked />}
                label="Aceito receber avisos por Push"
              />
            </Grid>
          </Grid>
          {camposInvalidos() && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <Typography>
                  Verifique se os campos obrigatórios foram preenchidos
                  corretamente!
                </Typography>
              </Alert>
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
                  CADASTRAR
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <ContatoWhatsApp></ContatoWhatsApp>
    </Container>
  );
}
