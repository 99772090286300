import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Logo from "@/components/Logo";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import { post } from "@/services/api";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoToaster,
} from "@/store/reducers/geral";
export default function DialogLoginCliente({
  infoDialogLogin,
  setInfoDialogLogin,
}) {
  const dispatch = useDispatch();
  const [avisoLogin, setAvisoLogin] = React.useState(false);
  const navigation = useNavigate();

  const Schema = Yup.object().shape({
    cpfCnpj: Yup.string().required("Informe seu CPF ou CNPJ"),
    password: Yup.string().required("Informe sua senha"),
  });

  const cancelar = () => {
    dispatch(setInfoDialogLogin({ open: false }));
  };

  const formik = useFormik({
    initialValues: {
      cpfCnpj: "",
      password: "",
      loginType: "CLIENTE",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        dispatch(activeSpinner());
        setAvisoLogin({});
        values.idRequestBind = infoDialogLogin.idRequestBind;
        values.tokenRequestBind = infoDialogLogin.tokenRequestBind;
        const result = await post("public/login", values);
        dispatch(cancelSpinner());
        if (result.error) {
          setAvisoLogin({ open: true, descricao: result.error });
          return;
        }
        sessionStorage.setItem(
          "dadosUsuarioLogado",
          JSON.stringify(result.data.user)
        );
        dispatch(
          setInfoToaster({
            open: true,
            message: "Login efetuado com sucesso",
            type: "success",
          })
        );
        navigation("/app/area-cliente/inicio");
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        setAvisoLogin({
          open: true,
          descricao:
            "Estamos atualizando nosso sistema. Por favor tente novamente em instantes.",
        });
      }
    },
  });
  const cadastrar = () => {
    sessionStorage.setItem("idRequestBind", infoDialogLogin.idRequestBind);
    sessionStorage.setItem(
      "tokenRequestBind",
      infoDialogLogin.tokenRequestBind
    );
    navigation("/app/cadastro");
  };

  const esqueciMinhaSenha = () => {
    sessionStorage.setItem("idRequestBind", infoDialogLogin.idRequestBind);
    sessionStorage.setItem(
      "tokenRequestBind",
      infoDialogLogin.tokenRequestBind
    );
    navigation("/app/esqueci-minha-senha");
  };

  return (
    <Dialog
      open={infoDialogLogin.open}
      maxWidth="xs"
      onClose={cancelar}
      aria-labelledby="alert-dialog-title"
      className="dialog-transacao"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <IconButton
          aria-label="close"
          onClick={cancelar}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} align="center">
            <Logo {...{ maxWidth: "70px" }}></Logo>
          </Grid>
        </Grid>
        <Grid container sx={{ p: { xs: 1 } }}>
          <Grid item xs={12}>
            <Alert severity="info">
              <Typography>
                Para continuar você deve realizar seu login.
              </Typography>
            </Alert>
          </Grid>

          {avisoLogin.open && (
            <Grid item xs={12}>
              <Alert
                severity={avisoLogin.type || "error"}
                onClose={() => {
                  setAvisoLogin({ open: false });
                }}
              >
                {avisoLogin.descricao}
              </Alert>
            </Grid>
          )}
        </Grid>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="cpfCnpj"
                name="cpfCnpj"
                label="CPF ou CNPJ"
                fullWidth
                autoComplete="username"
                variant="standard"
                value={formik.values.cpfCnpj}
                onChange={formik.handleChange}
                error={formik.touched.cpfCnpj && Boolean(formik.errors.cpfCnpj)}
                helperText={formik.touched.cpfCnpj && formik.errors.cpfCnpj}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Senha"
                type="password"
                autoComplete="current-password"
                fullWidth
                variant="standard"
                value={formik.values.password}
                onChange={(event) => {
                  formik.setFieldValue("password", event.target.value);
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                sx={{ mt: 3, ml: 1 }}
              >
                CONTINUAR
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <Box>
              <Button
                variant="text"
                fullWidth
                onClick={() => {
                  esqueciMinhaSenha();
                }}
              >
                ESQUECI MINHA SENHA
              </Button>
            </Box>
          </Grid>
        </form>
        <Typography align="center" variant="h6" sx={{ mt: 3 }}>
          Ainda não possuo conta!
        </Typography>

        <Grid item xs={12}>
          <Box>
            <Button
              variant="outlined"
              type="button"
              fullWidth
              sx={{ mt: 1, ml: 1 }}
              onClick={() => {
                cadastrar();
              }}
            >
              CRIAR CONTA
            </Button>
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
